import React, { Component } from 'react';

import NestedSiteTable from './NestedSiteTable';
import NestedUserTable from './NestedUserTable';

interface INestedTableWrapperProps {
  history?: unknown;
  type?: string;
  expandConfig?: { tablist: unknown; otherList: unknown };
  row?: unknown;
  isFetchingExpandData?: boolean;
  expandData?: unknown;
  expandType?: unknown;
  infoDetails?: unknown;
  selectedPopup?: unknown;
  handleRowAction?: (event?, row?, formDetails?, displayValue?, actionType?) => void;
  onExpandFetch?: (expandType, row) => void;
  handleInfo?: () => void;
  handleInfoClose?: () => void;
  onHandleAction?: () => void;
}

/**
 * Wraps the sub-table for each type of sub-table (to be displayed in a table when the "+" button is pressed).
 * Generally, this will wrap several specific table components, hidden by tabs, using `type` from props to define
 * the parent table type, and `expandType` to define the currently open tab in state.
 *
 * @constructor
 * @component
 */
export default class NestedTableWrapper extends Component<INestedTableWrapperProps> {
  render() {
    const { type, expandConfig, row, expandData, expandType } = this.props;

    const typeToTableMap = {
      site: (
        <NestedSiteTable
          handleRowAction={this.props.handleRowAction}
          onHandleAction={this.props.onHandleAction}
          row={row}
          expandType={expandType}
          tabs={expandConfig.tablist}
          expandData={expandData}
          onExpandFetch={this.props.onExpandFetch}
        />
      ),
      user: (
        <NestedUserTable
          sections={expandConfig.otherList}
          expandData={expandData}
          row={row}
          isFetchingExpandData={this.props.isFetchingExpandData}
        />
      ),
    };

    return typeToTableMap[type];
  }
}
