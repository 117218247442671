import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldArray, Formik, getIn } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import SlidingPane from 'react-sliding-pane';
import * as yup from 'yup';

const schema = yup.object().shape({
  products: yup.array().of(
    yup.object().shape({
      description: yup.string().required(),
    })
  ),
});

const EMPTY_PRODUCT_TEMPLATE = {
  description: '',
  size: '',
  id: null,
};

export default function InvestmentDescription(props) {
  const { investment, products, onSaveInvestmentDescription, onClose, isOpen } = props;
  const [state, setState] = useState({
    productMetaData: [],
    isLoaded: false,
    initialFormValues: [EMPTY_PRODUCT_TEMPLATE],
  });

  useEffect(() => {
    if (!state.productMetaData.length) {
      const initialFormValues = investment.products
        ? investment.products.map((product) => {
            return {
              description: product.product_id,
              size: product.size ?? '',
              id: product.id,
            };
          })
        : [EMPTY_PRODUCT_TEMPLATE];

      setState({
        ...state,
        initialFormValues,
        // Map product data into state for mapping as select options
        productMetaData: products.map((product) => {
          return {
            value: product.id,
            label: product.description,
          };
        }),
        isLoaded: true,
      });
    }
  }, [products, investment]);

  function handleSubmit(values) {
    onSaveInvestmentDescription(values);
  }

  function DescriptionFieldArray({ handleSubmit, setFieldValue }) {
    return (
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <FieldArray
          name="products"
          render={(props) => {
            const { form, remove, push } = props;
            const { values, errors, touched } = form;

            return (
              <div style={{ width: '100%' }}>
                {values.products.map((description, index) => {
                  function getUnits() {
                    return products.find((product) => product.id === Number(values.products[index].description))?.units;
                  }

                  const descriptionError = getIn(errors, `products[${index}].description`);
                  const descriptionTouch = getIn(touched, `products[${index}].description`);
                  const descriptionValue = getIn(values, `products[${index}].description`);

                  return (
                    <div key={index}>
                      <Form.Row>
                        <Form.Group as={Col} md="4">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            value={descriptionValue}
                            as={'select'}
                            name={`products.${index}.description`}
                            isInvalid={descriptionTouch && !!descriptionError}
                            isValid={descriptionTouch && !descriptionError}
                            onChange={(e) => {
                              e.preventDefault();
                              setFieldValue(`products.${index}.description`, e.currentTarget.value);
                            }}
                          >
                            <option value={''}>Select a description...</option>
                            {state.productMetaData.map((product, productIndex) => (
                              <option value={product.value} key={`option-${productIndex}-${index}`}>
                                {product.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>

                        <Col md="4">
                          <Form.Label>Size</Form.Label>
                          <Form.Control as={Field} type="number" name={`products[${index}].size`} />
                          <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Col>

                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            marginBottom: '5px',
                          }}
                          md={'1'}
                        >
                          <h1>{getUnits()}</h1>
                        </Col>

                        <Col
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                          md={'1'}
                        >
                          <Button
                            size={'lg'}
                            variant="success"
                            type="button"
                            onClick={() => push(EMPTY_PRODUCT_TEMPLATE)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          <Button
                            disabled={values.products.length === 1}
                            size={'lg'}
                            variant="danger"
                            type="button"
                            onClick={() => remove(index)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </Button>
                        </Col>
                      </Form.Row>
                    </div>
                  );
                })}
              </div>
            );
          }}
        />

        <Button
          style={{ margin: '2rem 0', height: '50px', width: '200px', marginRight: '0.5rem' }}
          size={'lg'}
          variant="success"
          type="submit"
        >
          Save Configuration
        </Button>

        <Button
          style={{ margin: '2rem 0', height: '50px', width: '200px', marginRight: '0.5rem' }}
          size={'lg'}
          variant="warning"
          type="button"
          onClick={onClose}
        >
          Close
        </Button>
      </Form>
    );
  }

  return (
    <SlidingPane
      isOpen={isOpen}
      title={'Investment Products'}
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        onClose();
      }}
    >
      <div>
        {state.isLoaded ? (
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={{
              products: state.initialFormValues,
            }}
            render={DescriptionFieldArray}
          />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </SlidingPane>
  );
}
