import { API, Auth } from 'aws-amplify';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FormControl, FormGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { dateTimeFormat, displayAPIErrorMessage, encryptCodes } from '../../common/utils-helper';
import LoaderButton from '../../components/LoaderButton';

interface IResetPasswordState {
  isLoading?: boolean;
  password?: string;
  confirmPassword?: string;
  currentPassword?: string;
  username?: string;
  type?: string;
}

export default class ResetPassword extends Component<RouteComponentProps, IResetPasswordState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      isLoading: false,
      password: '',
      confirmPassword: '',
      currentPassword: '',
      username: '',
      type: '',
    };
  }

  async componentDidMount() {
    if ((this.props.match.params as any).username && (this.props.match.params as any).type) {
      //    let user =this.props.location.state.user;
      this.setState({
        ...this.state,
        username: (this.props.match.params as any).username,
        type: (this.props.match.params as any).type,
      });
    }
  }

  validateForm() {
    return (
      this.state.currentPassword.length > 0 &&
      this.state.password.length > 0 &&
      this.state.confirmPassword.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ ...this.state, isLoading: true });
    let jwtToken = '';
    let user_id = '';
    try {
      await Auth.signIn(this.state.username, this.state.currentPassword)
        .then((user) => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            Auth.completeNewPassword(user, this.state.confirmPassword, { name: this.state.username })
              .then((user) => {
                jwtToken = user.signInUserSession.idToken.jwtToken;
                const email = user.signInUserSession.idToken.payload.email;
                const options = { headers: { 'Content-Type': 'application/json', Authorization: jwtToken } };
                const response = API.get('users', '/user/byname/' + this.state.username, options);

                response
                  .then((data) => {
                    if (data && data.data.length > 0) {
                      user_id = data.data[0].user_id;
                      localStorage.setItem('role', encryptCodes(data.data[0].role));
                      localStorage.setItem(
                        'awssession',
                        encryptCodes(JSON.stringify({ jwtToken: jwtToken, email: email }))
                      );
                      const bodyParam = {
                        user_last_login: dateTimeFormat(),
                        user_id: user_id,
                      };
                      const updateoptions = {
                        body: bodyParam,
                        headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
                      };
                      API.patch('users', '/user/users/' + user_id, updateoptions);
                      this.props.history.push('/sites');
                    } else {
                      toast.error("user doesn't exist on derms application!!", {
                        autoClose: 5000,
                      });
                    }
                  })
                  .catch((e) => {
                    displayAPIErrorMessage(e);
                  });
              })
              .catch((e) => {
                displayAPIErrorMessage(e);
                this.setState({ isLoading: false });
              });
          }
        })
        .catch((e) => {
          displayAPIErrorMessage(e);
          this.setState({ isLoading: false });
        });
    } catch (e) {
      displayAPIErrorMessage(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row login-row">
          <div className="login-bg col-xs-4 col-sm-7 col-md-6 col-lg-12 ">
            <div className="login-wrapper col-lg-3">
              <div className="login-logo text-center logo-img-pos"></div>
              <div className="login-content">
                <h3 className="text-center">Clipsal Solar Portal Set New Password</h3>
                <p className="text-center">
                  Welcome to the Clipsal Solar Distributed Energy Resource Management System.
                </p>
                <form className="login-form" onSubmit={this.handleSubmit}>
                  <div className="mb-4">
                    <FormGroup controlId="currentPassword">
                      <label>Current Password</label>
                      <FormControl
                        autoFocus
                        type="password"
                        value={this.state.currentPassword}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  </div>
                  <div className="mb-4">
                    <FormGroup controlId="password">
                      <label>Password</label>
                      <FormControl type="password" value={this.state.password} onChange={this.handleChange} />
                    </FormGroup>
                  </div>
                  <div className="mb-4">
                    <FormGroup controlId="confirmPassword">
                      <label>Confirm Password</label>
                      <FormControl value={this.state.confirmPassword} onChange={this.handleChange} type="password" />
                    </FormGroup>
                  </div>
                  <div className="form-group mt-5">
                    <LoaderButton
                      className="btn btn-lg btn-block btn-big btn-blue"
                      block
                      disabled={!this.validateForm()}
                      type="submit"
                      isLoading={this.state.isLoading}
                      text="Set New Password"
                      loadingText="Setting New Password…"
                    />
                  </div>
                </form>
                <div className="text-center">
                  <a href="#/">
                    <b>Click here</b>
                  </a>{' '}
                  to go back to login page.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
