import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/chakra-override.css';
import { Tenant } from '../../../api/api-user';
import ContentWrapper from '../../../components/ContentWrapper';
import * as yup from 'yup';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import { del, get, getTokenAndEmailFromSession, patch, post, put } from '../../../common/api-utils';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Flex,
  Heading,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useDisclosure,
  Icon,
  Center,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import { FaMapMarkerAlt } from 'react-icons/fa';
import ManualAddressDialog, { ManualAddressFormData } from '../../../components/ManualAddressDialog';
import { AUSTRALIAN_STATE_TO_TIMEZONE } from 'clipsal-cortex-utils/src/constants/timezone-states';
import { MdLocationPin } from 'react-icons/md';
import { mapTenantToApi, mapTenantToForm } from './tenant-form-helpers';
import UploadTenantLogo, { UploadedFile } from './UploadTenantLogo';
import { encode } from 'base64-arraybuffer';

export type BusinessData = {
  name: string;
  address: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
};

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY as string;

const schema = yup.object().shape({
  name: yup.string().trim().required('*Name is Required'),
  address: yup.string().trim().required('*Address Name is Required'),
  city: yup.string().trim().required('*City is Required'),
  state: yup.string().trim().required('*State is Required'),
  postCode: yup.string().trim().required('*Post Code is Required'),
  country: yup.string().trim().required('*Country is Required'),
});

const TenantForm = () => {
  const {
    register,
    handleSubmit: handleFormSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<BusinessData>({
    resolver: yupResolver(schema),
  });

  const params = useParams<{ id?: string }>();
  const tenantId = params?.id;

  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(!tenantId);
  const [shouldDisplayAutocompleteOptions, setShouldDisplayAutocompleteOptions] = useState(false);
  const [{ logoFile, isUploading, isDeleting, isDeleteModalOpen }, setLogoFile] = useState({
    logoFile: null as UploadedFile | null,
    isUploading: false,
    isDeleteModalOpen: false,
    isDeleting: false,
  });
  const toast = useToast({
    isClosable: true,
  });
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const history = useHistory();

  const {
    isOpen: isManualAddressDialogOpen,
    onOpen: onOpenManualAddressDialog,
    onClose: onCloseManualAddressDialog,
  } = useDisclosure();

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      types: [],
      componentRestrictions: { country: ['AU', 'US', 'NZ'] },
    } as any,
  });

  // Check if all fields are filled and enable submit button.
  // Using subscription so that we do not rerender whole component on value changes
  useEffect(() => {
    const subscription = watch((values) => {
      const isFormFilled = Object.values(values).every((value) => !!value);
      setIsFormFilled(isFormFilled);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const { jwtToken } = await getTokenAndEmailFromSession();
        const tenant = await get<Tenant>('tenants', `/common/tenants/${tenantId}`, jwtToken);
        reset(mapTenantToForm(tenant));
        const tenantLogoUrl = tenant.logo_url;
        if (tenantLogoUrl) {
          setLogoFile((prevState) => ({
            ...prevState,
            logoFile: {
              url: tenantLogoUrl,
              fileData: null,
              extension: '',
              isPersisted: true,
            },
          }));
        }
      } catch (e) {
        displayAPIErrorMessage(e);
      }
      setIsLoaded(true);
    }
    if (tenantId) fetchAPI();
  }, []);

  const handleCloseDeleteModal = () => {
    setLogoFile((prevState) => ({
      ...prevState,
      isDeleteModalOpen: false,
    }));
  };

  const updateTenantLogo = async (tenantId: string | number, fileData: ArrayBuffer) => {
    try {
      const { jwtToken } = await getTokenAndEmailFromSession();
      const updatedTenant = await put<Tenant>(
        'tenants',
        `/common/tenants/${tenantId}/logo`,
        {
          file_base64: encode(fileData),
        },
        jwtToken
      );
      setLogoFile((prevState) => ({
        ...prevState,
        isUploading: false,
        /* 
            Since the logo url will not change as image uuid is not changed on update. 
            We need to add timestamp to invalidate browser cache.
            https://stackoverflow.com/a/13067981 
          */
        logoFile: {
          url: `${updatedTenant.logo_url || ''}?current_time=${new Date().getTime()}`,
          fileData: null,
          extension: '',
          isPersisted: true,
        },
      }));
      // update state
    } catch (error) {
      toast({
        title: 'Something went wrong uploading image.',
        description: 'Please try again. If this continues, please contact support.',
        status: 'error',
      });
      setLogoFile((prevState) => ({
        ...prevState,
        isUploading: false,
        logoFile: null,
      }));
    }
  };

  const handleCreateTenant = async (values: BusinessData) => {
    const data = mapTenantToApi(values);

    if (logoFile) {
      setLogoFile((prevState) => ({
        ...prevState,
        isUploading: true,
      }));
    }

    try {
      const { jwtToken } = await getTokenAndEmailFromSession();
      const tenant = await post<Tenant>('tenants', `/common/tenants`, data, jwtToken);
      if (logoFile) await updateTenantLogo(tenant.tenant_id, logoFile.fileData);
      toast({
        title: 'Successfully created new tenant',
        status: 'success',
      });
      history.push(`/tenants/tenant/${tenant.tenant_id}/edit`);
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  const handleUpdateTenant = async (values: BusinessData) => {
    const data = mapTenantToApi(values);
    try {
      const { jwtToken } = await getTokenAndEmailFromSession();
      await patch<Tenant>('tenants', `/common/tenants/${tenantId}`, { ...data, tenant_id: tenantId }, jwtToken);
      toast({
        title: 'Successfully edited tenant',
        status: 'success',
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  async function handleSubmit(values: BusinessData) {
    setIsLoading(true);
    await (tenantId ? handleUpdateTenant : handleCreateTenant)(values);
    setIsLoading(false);
  }

  async function handleSelectLocation(location: AutocompletePrediction) {
    const currentValues = getValues();

    placesService?.getDetails({ placeId: location.place_id }, async (details) => {
      const locationData: Record<string, string | undefined> = {};

      // iterate over address components to populate location data
      details?.address_components?.forEach((component) => {
        if (component.types.includes('locality')) {
          locationData.city = component?.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          locationData.state = component?.short_name;
        } else if (component.types.includes('country')) {
          locationData.country = component?.long_name;
        } else if (component.types.includes('postal_code')) {
          locationData.postCode = component?.long_name;
        }
      });

      const termsLength = location.terms.length;

      reset({
        ...currentValues,
        address: location.description,
        city: locationData.city || location.terms[termsLength - 3]?.value,
        state: locationData.state || location.terms[termsLength - 2]?.value,
        country: locationData.country || location.terms[termsLength - 1]?.value,
        postCode: locationData.postCode,
      });

      setShouldDisplayAutocompleteOptions(false);
    });
  }

  function handleSubmitManualAddress({ country, streetAddress, unit, suburb, state, postcode }: ManualAddressFormData) {
    // @TODO: when we move to US, look at timezones for US -- some states have multiple timezones
    const timezone = country === 'Australia' ? AUSTRALIAN_STATE_TO_TIMEZONE[state] : 'America/Los_Angeles';

    reset({
      ...getValues(),
      address: `${unit ? unit + '/' : ''}${streetAddress}, ${suburb}, ${state}`,
      city: suburb,
      state,
      country,
      // NOTE: Probably a bug
      // @ts-ignore
      timezone,
      // NOTE: Probably a bug
      // @ts-ignore
      postCode: postcode.toString(),
    });

    onCloseManualAddressDialog();
    setShouldDisplayAutocompleteOptions(false);
  }

  function PredictionsList() {
    return placePredictions.length ? (
      <Box width={'100%'} mt={2} py={3}>
        <Heading mb={1} size={'md'}>
          Results
        </Heading>
        {placePredictions.map((location, i) => (
          <Box
            data-testid={`site-address-prediction-${i}`}
            className={'site-address-prediction-list'}
            borderBottom={i === placePredictions.length - 1 ? '1px solid grey' : undefined}
            borderTop={'1px solid grey'}
            key={`google-maps-location-${i}`}
            _hover={{ background: 'gray.50' }}
            onClick={async () => handleSelectLocation(location)}
            cursor={'pointer'}
            py={2}
          >
            <Flex align={'center'} as={'button'} type={'button'}>
              <Box mr={1}>
                <FaMapMarkerAlt />
              </Box>
              <Text fontWeight={600} fontSize={'sm'}>
                {location.description}
              </Text>
            </Flex>
          </Box>
        ))}
        <Text mt={2} fontSize={'xs'}>
          Powered by Google
        </Text>
      </Box>
    ) : null;
  }
  return (
    <ContentWrapper
      title={`${tenantId ? 'Edit' : 'Create'} Tenant`}
      breadcrumbs={[{ title: 'Tenants', url: '#/tenants' }, { title: `${tenantId ? 'Edit' : 'Create'} Tenant` }]}
    >
      {isLoaded ? (
        <Box maxW={700}>
          <Box onSubmit={handleFormSubmit(handleSubmit)} as={'form'} px={4} className="override-parent">
            <FormControl mt={4} isInvalid={!!errors.name}>
              <FormLabel>Tenant name</FormLabel>
              <Input {...register('name')} placeholder="Enter tenant name" data-testid={'tenant-name-input'} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.address} mt={4}>
              <FormLabel>Tenant address</FormLabel>
              <InputGroup>
                <Input
                  {...register('address')}
                  onChange={(e) => {
                    getPlacePredictions({ input: e.currentTarget.value });
                    setValue('address', e.currentTarget.value);
                    setShouldDisplayAutocompleteOptions(true);
                  }}
                  placeholder={'12/22 George Street, Sydney, NSW'}
                  type="text"
                  data-testid={'tenant-address-input'}
                />
                <InputRightElement height={'100%'}>
                  <Icon as={MdLocationPin} w={10} h={10} color="customBlue.500" />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
            </FormControl>

            <Box
              onClick={onOpenManualAddressDialog}
              as="button"
              type="button"
              mt={1}
              fontSize="md"
              color="customBlue.500"
            >
              Enter address manually
            </Box>

            {/* Site address autocomplete */}
            <Box w={'100%'}>
              {!isPlacePredictionsLoading ? (
                shouldDisplayAutocompleteOptions && <PredictionsList />
              ) : (
                <Flex direction={'column'} justify={'center'} align={'center'}>
                  <Spinner size={'lg'} />
                  <Text fontSize={'sm'}>Loading suggestions...</Text>
                </Flex>
              )}
            </Box>

            <FormControl my={4} data-testid="business-logo">
              <FormLabel>Upload logo (Optional)</FormLabel>
              <Text>You logo will be displayed in various parts of the app for you & your team members</Text>

              <UploadTenantLogo
                isUploading={isUploading || isDeleting}
                onDeleteFile={async (file: UploadedFile) => {
                  if (file.isPersisted) {
                    setLogoFile((prevState) => ({ ...prevState, isDeleteModalOpen: true }));
                  } else {
                    setLogoFile((prevState) => ({ ...prevState, logoFile: null }));
                  }
                }}
                logoFile={logoFile}
                onUploadImage={async (fileData: ArrayBuffer, extension: string) => {
                  const base64ImageData = encode(fileData);
                  const imageSizeInKB = Math.ceil((base64ImageData.length * 6) / 8 / 1024);

                  // Limit image size to 2MB
                  if (imageSizeInKB > 2048) {
                    toast({
                      title: 'Image Too Large',
                      description: 'Please try again. Maximum upload size is 2MB.',
                      status: 'error',
                    });
                    return;
                  }

                  setLogoFile((prevState) => ({
                    ...prevState,
                    logoFile: {
                      url: `data:image/${extension};base64,${base64ImageData}`,
                      fileData,
                      extension,
                      isPersisted: false,
                    },
                    isUploading: tenantId ? true : false,
                  }));

                  if (tenantId) {
                    await updateTenantLogo(tenantId, fileData);
                  }
                }}
              />
            </FormControl>

            <Button
              isLoading={isLoading}
              isDisabled={!isFormFilled}
              type="submit"
              mt={4}
              data-testid={'tenant-submit-btn'}
            >
              Submit
            </Button>
          </Box>
          {isManualAddressDialogOpen && (
            <ManualAddressDialog
              currentValues={{
                country: getValues().country,
                address: getValues().address,
                postCode: getValues().postCode,
                state: getValues().state,
                suburb: getValues().city,
              }}
              isOpen={isManualAddressDialogOpen}
              onClose={onCloseManualAddressDialog}
              onSubmitManualAddress={handleSubmitManualAddress}
            />
          )}

          <AlertDialog
            isOpen={isDeleteModalOpen}
            leastDestructiveRef={cancelRef}
            onClose={handleCloseDeleteModal}
            isCentered
            size="xl"
          >
            <AlertDialogOverlay>
              <AlertDialogContent
                data-testid={`delete-dialog`}
                className="override-parent"
                rounded={5}
                overflow="hidden"
                px={6}
                pt={4}
              >
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Logo
                </AlertDialogHeader>

                <AlertDialogBody px={0}>Are you sure you want to delete current logo?</AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={handleCloseDeleteModal} data-testid={`delete-cancel-button`}>
                    Cancel
                  </Button>
                  <Button
                    data-testid={`delete-confirm-button`}
                    colorScheme="red"
                    isLoading={isDeleting}
                    onClick={async () => {
                      setLogoFile((prevState) => ({ ...prevState, isDeleting: true }));

                      try {
                        const { jwtToken } = await getTokenAndEmailFromSession();
                        await del<Tenant>('tenants', `/common/tenants/${tenantId}/logo`, jwtToken);
                        setLogoFile((prevState) => ({
                          ...prevState,
                          isDeleting: false,
                          logoFile: null,
                          isDeleteModalOpen: false,
                        }));
                        // update tenant
                      } catch (error) {
                        toast({
                          title: 'Something went wrong deleting image.',
                          description: 'Please try again. If this continues, please contact support.',
                          status: 'error',
                        });
                        setLogoFile((prevState) => ({
                          ...prevState,
                          isDeleting: false,
                          isDeleteModalOpen: false,
                        }));
                      }
                    }}
                    ml={3}
                  >
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Box>
      ) : (
        <Center minH={300}>
          <Spinner thickness="4px" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      )}
    </ContentWrapper>
  );
};

export default TenantForm;
