import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Button } from '@chakra-ui/button';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { InstalledDevice } from '../../../api/api-device';
import { del, getTokenAndEmailFromSession } from '../../../common/api-utils';
import ModelManufacturerSiteIdentifierFields from './ModelManufacturerSiteIdentifierFields';
import { CommonFieldListProps, InverterFormData } from './site-devices-types';
import NestedSubarrayFieldArray, { EMPTY_SUBARRAY_TEMPLATE } from './NestedSubarrayFieldArray';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Switch } from '@chakra-ui/switch';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { Input } from '@chakra-ui/input';
import { displayAPIErrorMessage } from '../../../common/utils-helper';

export const EMPTY_INVERTER_TEMPLATE: InverterFormData = {
  inverterId: null,
  manufacturer: '',
  model: '',
  siteIdentifier: '',
  apiKey: null,
  serialNumber: null,
  subArrays: [EMPTY_SUBARRAY_TEMPLATE],
  hasBattery: false,
};

const InverterForm = (props: CommonFieldListProps) => {
  const { control, siteId, errors, register } = props;
  const { fields, append, remove } = useFieldArray({
    name: `inverters`,
    control,
  });

  const [expandedIndex, setExpandedIndex] = useState<number>(-1);

  useEffect(() => {
    setExpandedIndex(fields.length - 1);
  }, [fields.length]);

  async function handleDeleteInverter(inverterId: number, index: number) {
    if (window.confirm('Are you sure you want to delete this inverter?')) {
      if (inverterId) {
        try {
          const { jwtToken } = await getTokenAndEmailFromSession();
          await del<InstalledDevice>('site-devices', `/site/sites/${siteId}/devices/${inverterId}`, jwtToken);
        } catch (e) {
          displayAPIErrorMessage(e);
        }
      }

      remove(index);
    }
  }

  return (
    <Box>
      <Accordion
        defaultIndex={0}
        allowToggle
        index={expandedIndex}
        onChange={(clickedIndex: number) => setExpandedIndex(clickedIndex)}
      >
        {fields.map((field, index) => {
          const errorsAtIndex = errors?.inverters?.[index];
          return (
            <AccordionItem data-testid={'inverter-form'} key={`${field.id}-${index}`}>
              <AccordionButton role={'button'} as={Box} py={4}>
                <Flex w={'100%'} justify={'space-between'} align={'center'}>
                  <Heading>Inverter {index + 1}</Heading>
                  <Flex align={'center'}>
                    <Button
                      data-testid={`remove-inverter-btn-${index}`}
                      type="button"
                      variant={'ghost'}
                      onClick={() => handleDeleteInverter(field.inverterId, index)}
                      size={'xs'}
                      style={{ fontSize: '12px' }}
                      mr={2}
                      colorScheme="red"
                      aria-label="Delete inverter"
                    >
                      Remove
                    </Button>
                    <AccordionIcon w={8} h={8} mr={3} />
                  </Flex>
                </Flex>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <ModelManufacturerSiteIdentifierFields
                  {...{ ...props, index, field, deviceType: 'INVERTER', fieldKey: 'inverters' }}
                />
                <FormControl isInvalid={!!errorsAtIndex?.hasBattery} mt={3} id={`${field.id}_hasBattery`}>
                  <FormLabel>Has Battery</FormLabel>
                  <Switch
                    size="lg"
                    colorScheme="clipsalGreen"
                    defaultChecked={field.hasBattery}
                    {...register(`inverters.${index}.hasBattery` as const)}
                  />
                  <FormErrorMessage>{errors?.inverters?.[index]?.hasBattery?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errorsAtIndex?.serialNumber} mt={3}>
                  <FormLabel>Serial Number</FormLabel>
                  <Input
                    {...register(`inverters.${index}.serialNumber` as const)}
                    py={'20px'}
                    placeholder="Enter Serial Number"
                  />
                  <FormErrorMessage>{errorsAtIndex?.serialNumber?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errorsAtIndex?.apiKey} my={3}>
                  <FormLabel>API Key</FormLabel>
                  <Input {...register(`inverters.${index}.apiKey` as const)} py={'20px'} placeholder="Enter API Key" />
                  <FormErrorMessage>{errorsAtIndex?.apiKey?.message}</FormErrorMessage>
                </FormControl>

                <NestedSubarrayFieldArray {...props} nestIndex={index} />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      {!fields.length && (
        <Alert status="info" my={4} rounded={4}>
          <AlertIcon w={8} h={8} />
          <AlertDescription py={4}>No Inverter! Please click "Add inverter" to add one.</AlertDescription>
        </Alert>
      )}
      <Button
        data-testid={'add-inverter-btn'}
        py={8}
        my={7}
        onClick={() => append(EMPTY_INVERTER_TEMPLATE)}
        type="button"
      >
        Add Inverter
      </Button>
    </Box>
  );
};

export default InverterForm;
