import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { get, getTokenAndEmailFromSession, patch } from '../../common/api-utils';
import { mapArrayToSelectFormat, reducePropertyArrayToModelStructure } from '../../common/array-utils';
import config from '../../common/site-config';
import { displayAPIErrorMessage, formValidation } from '../../common/utils-helper';
import Autocomplete from '../../components/Autocomplete';

const ELECTRICAL_CONFIGS = [
  { label: '1Ph', value: '1ph' },
  { label: '2Ph', value: '2ph' },
  { label: '3Ph', value: '3ph' },
];

const INITIAL_STATE = {
  site: {
    site_name: '',
    site_city: '',
    site_state: '',
    site_zipcode: '',
    site_country: '',
    site_latitude: '',
    site_longitude: '',
    street_address: '',
    site_address_line1: '',
    site_address_line2: '',
    site_address_line3: '',
    timezone: '',

    distributor_id: 0,
    tenant_id: 0,
    electrical_config: '',
    clipsal_solar_id: null,
  },
  isLoaded: false,
  imagePath: null,
  imageURL: null,
  isImageDisplayed: false,
  isAddressChanged: false,
  displayLoaderWithForm: false,
  electricalConfigs: ELECTRICAL_CONFIGS,
  distributors: [],
  formData: {
    selectedElectricalConfig: null,
    electricalConfigOptions: [],
    selectedDistributor: null,
    distributorOptions: [],
    selectedTenant: null,
    tenantOptions: [],
  },
};

/**
 * Houses form functionality for basic site information to be used within the `Site` component.
 *
 * // @TODO: refactor with formik/yup at some point to remove boilerplate
 *
 * @param siteId The ID of the site being modified, if applicable. Otherwise `null`.
 * @param handleCancel Cancel the current form.
 * @param isEditing Whether the current form is editing an existing record.
 * @param history History from parent component props
 * @param investmentTab Ref to the investment tab from the parent component.
 * @param isParentLoaded Whether the parent component has finished loading.
 *
 * @returns {JSX.Element}
 *
 * @constructor
 * @component
 */
export default function SiteBasicInformation({ siteId, handleCancel, isEditing, history, isParentLoaded }) {
  const [state, setState] = useState(INITIAL_STATE);

  // Runs whenever `siteId` changes (generally once, after initial render)
  useEffect(() => {
    async function initialFetch() {
      let newState = { ...state };
      const sessionData = await getTokenAndEmailFromSession();

      if (siteId) {
        const siteData = await fetchSite(siteId, sessionData.jwtToken);
        if (!siteData.distributor && siteData.site_latitude && siteData.site_longitude) {
          siteData.distributor = await fetchDistributorByLocation(
            siteData.site_latitude,
            siteData.site_longitude,
            sessionData.jwtToken
          );
        }

        const site = reducePropertyArrayToModelStructure(siteData, PROPERTIES_TO_SAVE);

        newState = {
          ...newState,
          site,
          imageURL: await fetchSiteImageURL(),
        };
      }

      setState({ ...newState, ...(await initForm(newState)), isLoaded: true });
    }

    if (isParentLoaded) {
      initialFetch();
    }
  }, [siteId, isParentLoaded]);

  const fetchSiteImageURL = async () => {
    try {
      const { jwtToken } = await getTokenAndEmailFromSession();
      const imageData = await get('sites', `/site/sites/${siteId}/image?size=desktop`, jwtToken);

      if (imageData && imageData.base64_file && imageData.file_type) {
        return `data:${imageData.file_type};base64, ${imageData.base64_file}`;
      }
    } catch (e) {
      displayAPIErrorMessage(e);
    }

    return null;
  };

  const fetchDistributorByLocation = async (latitude, longitude, token) => {
    return API.get('site_distributor', '/site/distributor?latitude=' + latitude + '&longitude=' + longitude, {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  };

  const initForm = async (newState) => {
    try {
      const { jwtToken } = await getTokenAndEmailFromSession();
      const [distributors, tenants] = await Promise.all([fetchDistributors(jwtToken), fetchTenants(jwtToken)]);
      const tenantOptions = tenants.data.map(mapArrayToSelectFormat('tenant_name', 'tenant_id'));
      const distributorOptions = distributors.map(mapArrayToSelectFormat('distributor_name', 'id'));
      newState.distributors = distributors;
      newState.formData.electricalConfigOptions = newState.electricalConfigs;
      newState.formData = { ...newState.formData, distributorOptions, tenantOptions };

      if (isEditing) {
        // Fetch the site timezone if it's empty
        if (!newState.site.timezone && newState.site.latitude && newState.site.longitude) {
          const uri = `https://maps.googleapis.com/maps/api/timezone/json?location=${newState.latitude},${
            newState.longitude
          }&timestamp=${Math.floor(Date.now() / 1000)}&language=es&key=${config.general.google_api}`;
          newState.site.timezone = (await axios.get(uri)).data.timeZoneId;
        }

        if (newState.site.electrical_config) {
          newState.formData.selectedElectricalConfig = {
            label: newState.site.electrical_config,
            value: newState.site.electrical_config,
          };
        }

        if (newState.site.distributor_id) {
          const selectedDistributor = distributorOptions.find((dist) => dist.value === newState.site.distributor_id);
          newState.formData.selectedDistributor = {
            label: selectedDistributor.label,
            value: selectedDistributor.value,
          };
        }

        if (newState.site.tenant) {
          const { tenant_id, tenant_name } = newState.site.tenant;
          newState.site.tenant_id = tenant_id;
          newState.formData.selectedTenant = { label: tenant_name, value: tenant_id };
        }
      }
    } catch (e) {
      displayAPIErrorMessage(e);
    }

    return newState;
  };

  const handleSelectChange = (siteProperty, formDataProperty) => {
    return (event) => {
      setState({
        ...state,
        site: {
          ...state.site,
          [siteProperty]: event.value,
        },
        formData: {
          ...state.formData,
          [formDataProperty]: event,
        },
      });
    };
  };

  const handleSelectLocation = async (locationData) => {
    const address = locationData.formatted_address;
    const addressArray = locationData.address_components;
    const city = GoogleMapsLocator.getCity(addressArray);
    const siteState = GoogleMapsLocator.getState(addressArray);
    const country = GoogleMapsLocator.getCountry(addressArray);
    const zipcode = GoogleMapsLocator.getZipCode(addressArray);
    const lat = locationData.geometry.location.lat();
    const lng = locationData.geometry.location.lng();
    let isAddressChanged = false;
    setState((prevState) => ({
      ...prevState,
      displayLoaderWithForm: true,
    }));

    try {
      const { jwtToken } = await getTokenAndEmailFromSession();

      const timezone = await axios.get(
        'https://maps.googleapis.com/maps/api/timezone/json?location=' +
          lat +
          ',' +
          lng +
          '&timestamp=' +
          Math.floor(Date.now() / 1000) +
          '&language=es&key=' +
          config.general.google_api
      );

      if (isEditing && (state.site.site_latitude !== lat || state.site.site_longitude !== lng)) {
        isAddressChanged = true;
      }

      const siteDistributor = (await fetchDistributorByLocation(lat, lng, jwtToken)) ?? '';
      const distributorOptions = state.distributors.map(mapArrayToSelectFormat('distributor_name', 'id'));

      // using lowercased values for equality check as sometimes siteDistributors are lowercased
      // for example: united energy
      const distributor_id = distributorOptions.find(
        (distributor) => distributor.label.toLowerCase() === siteDistributor.toLowerCase()
      )?.value;
      const selectedDistributor = {
        label: siteDistributor,
        value: distributor_id,
      };

      const siteFormState = {
        site_name: state.site.site_name || '',
        site_city: city || '',
        site_state: siteState || '',
        site_country: country || '',
        site_zipcode: zipcode || '',
        site_latitude: lat.toString(),
        site_longitude: lng.toString(),
        street_address: address?.split(',')?.[0],
        site_address_line1: address,
        timezone: timezone.data.timeZoneId,
        distributor_id,
      };

      const site = { ...state.site, ...siteFormState };

      const imagePath = async () => await fetchSiteImageURL();

      setState((prevState) => ({
        ...prevState,
        site,
        imagePath: imagePath,
        isAddressChanged,
        displayLoaderWithForm: false,
        distributors: state.distributors,
        formData: {
          ...prevState.formData,
          selectedElectricalConfig: null,
          electricalConfigOptions: ELECTRICAL_CONFIGS,
          selectedDistributor: selectedDistributor,
          distributorOptions: distributorOptions,
        },
      }));

      toast.success('Automatically populated geolocation data for site', {
        autoClose: 2000,
      });
    } catch (e) {
      displayAPIErrorMessage(e);

      setState((prevState) => ({
        ...prevState,
        displayLoaderWithForm: false,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, displayLoaderWithForm: true }));

    try {
      const validationMsg = await formValidation(state.site, VALIDATION_MODEL);
      if (validationMsg) {
        toast.error(validationMsg, {
          autoClose: 5000,
        });
        setState((prevState) => ({ ...prevState, displayLoaderWithForm: false }));
        return;
      }

      const { jwtToken } = await getTokenAndEmailFromSession();

      // Clone site state object and remove keys we don't want in the back-end
      const body = { ...state.site };

      const options = {
        headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
        body,
      };

      if (isEditing) {
        await patch('site', `/site/sites/${siteId}`, body, jwtToken);

        toast.success('Site  updated successfully', {
          autoClose: 5000,
        });
        setState((prevState) => ({ ...prevState, displayLoaderWithForm: false }));
      } else {
        delete body.clipsal_solar_id;
        const addedSite = await API.post('sites', '/site/sites', options);

        if (addedSite) {
          const newSiteId = addedSite.clipsal_solar_id;
          history.push(`/sites/${newSiteId}/edit`);
          window.location.reload();
        }

        toast.success('Site  added successfully', {
          autoClose: 5000,
        });
      }
    } catch (e) {
      displayAPIErrorMessage(e);
      setState((prevState) => ({ ...prevState, displayLoaderWithForm: false }));
    }
  };

  const handleInputChange = (event) => {
    const newValue = { [event.currentTarget.id]: event.currentTarget.value };
    setState((prevState) => ({
      ...prevState,
      site: { ...prevState.site, ...newValue },
    }));
  };

  // fixing react-select style issues
  const selectStyles = {
    control: (provided) => ({ ...provided, padding: 2 }),
    menu: (styles) => ({ ...styles, zIndex: 999 }),
  };

  return (
    <div>
      {state.isLoaded && isParentLoaded ? (
        <div className="custom-txtfield m-0 w-75">
          {state.displayLoaderWithForm && <Loader loaded={!state.displayLoaderWithForm} />}
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>Site Name</label>
              <input
                type="text"
                className="form-control"
                id="site_name"
                placeholder="Enter the Site name"
                value={state.site.site_name ?? ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>Search Address</label>
              <Autocomplete
                placeholder={'Enter the Address..'}
                onPlaceChanged={handleSelectLocation}
                address={state.site.site_address_line1 ?? ''}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>Additional Address</label>
              <input
                type="text"
                className="form-control"
                id="site_address_line2"
                placeholder="Enter Addtional Address"
                value={state.site.site_address_line2 ?? ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>Country</label>
              <input
                type="text"
                disabled
                className="input-disable-bg"
                id="site_country"
                placeholder="Enter the Country"
                value={state.site.site_country ?? ''}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>Latitude</label>
              <input
                type="number"
                className="form-control"
                id="site_latitude"
                placeholder="Enter site latitude"
                value={state.site.site_latitude ?? ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>Longitude</label>
              <input
                type="number"
                className="form-control"
                id="site_longitude"
                placeholder="Enter site longitude"
                value={state.site.site_longitude ?? ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>State</label>
              <input
                type="text"
                disabled
                className="input-disable-bg"
                id="site_state"
                placeholder="Enter the State"
                value={state.site.site_state ?? ''}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>City</label>
              <input
                type="text"
                disabled
                className="input-disable-bg"
                id="site_city"
                placeholder="Enter the City"
                value={state.site.site_city ?? ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>Postal code</label>
              <input
                type="text"
                disabled
                className="input-disable-bg"
                id="site_zipcode"
                placeholder="Enter the Postal code"
                value={state.site.site_zipcode ?? ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>Time Zone</label>
              <input
                type="text"
                disabled
                className="input-disable-bg"
                id="site_timezone"
                placeholder="Time Zone"
                value={state.site.timezone ?? ''}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>Distributor</label>
              <Select
                styles={selectStyles}
                className="custom-select-new"
                options={state.formData.distributorOptions}
                placeholder="Select distributor"
                value={state.formData.selectedDistributor}
                onChange={handleSelectChange('distributor_id', 'selectedDistributor')}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="label-zindex">Electrical Configuration</label>
              <div className="form-group">
                <Select
                  styles={selectStyles}
                  className="custom-select-new"
                  options={state.formData.electricalConfigOptions}
                  placeholder="Select electrical configuration"
                  value={state.formData.selectedElectricalConfig}
                  onChange={handleSelectChange('electrical_config', 'selectedElectricalConfig')}
                />
                <div className="invalid-feedback">Example invalid custom select feedback</div>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>Fleet</label>
              <div className="form-group">
                <Select
                  styles={selectStyles}
                  className="custom-select-new"
                  options={state.formData.tenantOptions}
                  placeholder="Select tenant"
                  value={state.formData.selectedTenant}
                  onChange={handleSelectChange('tenant_id', 'selectedTenant')}
                />
              </div>
            </div>
          </div>
          {isEditing && (
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label>Site Image :</label>
                <div
                  className="image-display"
                  onClick={() => {
                    setState({
                      ...state,
                      isImageDisplayed: true,
                    });
                  }}
                >
                  ...
                </div>

                {state.isImageDisplayed && (
                  <div className="image-section">
                    <button
                      type="button"
                      className="image-close"
                      onClick={() => setState({ ...state, isImageDisplayed: false })}
                    >
                      &times;
                    </button>
                    <img alt={'Site'} src={state.imageURL} className="image-size" />
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="btn-wrapper text-right">
            <button className="btn btn-upload" onClick={handleCancel} disabled={state.displayLoaderWithForm}>
              <FontAwesomeIcon className="btn-img-pos" icon={faTimes} />
              Cancel
            </button>
            <button
              className="btn btn-upload ml-3"
              type="button"
              disabled={state.displayLoaderWithForm}
              onClick={handleSubmit}
            >
              <FontAwesomeIcon className="btn-img-pos" icon={faSave} />
              {isEditing ? 'Save Changes' : 'Add Site'}
            </button>
          </div>
        </div>
      ) : (
        <Loader loaded={false} />
      )}
    </div>
  );
}

const PROPERTIES_TO_SAVE = [
  'clipsal_solar_id',
  'distributor_id',
  'electrical_config',
  'site_address_line1',
  'site_address_line2',
  'site_city',
  'site_country',
  'site_latitude',
  'site_longitude',
  'site_name',
  'site_state',
  'site_zipcode',
  'timezone',
  'tenant',
];

// Used to iterate form fields in this component
const VALIDATION_MODEL = [
  { value: 'site_name', label: 'Site Name', type: 'number', required: true },
  { value: 'site_address_line1', label: 'Search Address', type: 'string', required: true },
  { value: 'electrical_config', label: 'Electrical Configuration', type: 'string', required: true },
];

/** Helper methods and functions */

const fetchSite = async (id, token) => {
  return API.get('sites', '/site/sites/' + id, {
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });
};
const fetchDistributors = async (token) => {
  return API.get('distributors', '/tariff/distributors', {
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });
};

const fetchTenants = async (token) => {
  return API.get('tenants', '/common/tenants', {
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });
};

class GoogleMapsLocator {
  static getLineFromLocationData(addressArray, lineTypesToFind) {
    for (const index in addressArray) {
      if (addressArray.hasOwnProperty(index)) {
        const addressLine = addressArray[index];
        const found = addressLine.types.find((type) => lineTypesToFind.includes(type));

        if (found) {
          return addressLine;
        }
      }
    }

    return null;
  }

  static getCity = (addressArray) => GoogleMapsLocator.getLineFromLocationData(addressArray, ['locality'])?.long_name;

  static getState = (addressArray) =>
    GoogleMapsLocator.getLineFromLocationData(addressArray, ['administrative_area_level_1'])?.short_name;

  static getCountry = (addressArray) => GoogleMapsLocator.getLineFromLocationData(addressArray, ['country'])?.long_name;

  static getZipCode = (addressArray) =>
    GoogleMapsLocator.getLineFromLocationData(addressArray, ['postal_code'])?.long_name;
}
