import { faEllipsisV, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { CaretDownSquare, CaretUpSquare } from 'react-bootstrap-icons';
import BootstrapTable, { RowEventHandlerProps } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import siteConfig from '../../common/site-config';
import { domain_validation } from '../../common/utils-helper';
import NestedTableWrapper from './NestedTableWrapper';
import TableConfirmationBox from './TableConfirmationBox';

export type SortOrder = 'asc' | 'desc';

export type SortType = 'sort' | 'pagination';

export type HandleTableChangeProps = {
  changeType: SortType;
  page: number;
  sizePerPage: number;
  sortField: string;
  sortOrder: SortOrder;
};

const INITIAL_STATE = {
  selectedColumn: '',
  expandedRows: [],
  confirmType: null,
  selectedRow: '',
  type: '',
  isConfirmDialogOpen: false,
  page: 1,
  fetch_list: [],
  selectedItem: {},
  itemNameToConfirm: null,
  sortIconValue: '',
};

interface TableProps {
  history?: unknown;
  type?: string;
  subType?: string;
  tableConfig?: unknown;
  data: any[];
  isFetchingExpandData?: boolean;
  expandFetchData?: unknown;
  expandType?: string;
  showSiteImageFormatter?: any;
  itemNameToConfirm?: string;
  sortOrder: SortOrder;
  page: number;
  sizePerPage: number;
  totalSize: number;
  remote?: boolean;
  isRowExpandable?: boolean;
  rowEvents?: RowEventHandlerProps;
  onHandleAction?: (row?, actionType?, selectedRow?, type?) => void;
  onExpandFetch?: (expandType, row) => void;
  onChangePageSize: (event) => void;
  onTableChange: ({ changeType, page, sizePerPage, sortField, sortOrder }: HandleTableChangeProps) => void;
}

/**
 * The top-level `Table` component handles all functionality relating to data tables. Extracts various levels of nested
 * components to handle specific functionality for tables -- mostly sub-table functionality when expanding a row, as each
 * table structure is considerably different.
 *
 * @constructor
 * @component
 */
export default class Table extends Component<TableProps> {
  state = INITIAL_STATE;

  ellipsisFormatter = (cell) => {
    return (
      <>
        <div title={cell} className="ellipsis">
          {cell}
        </div>
      </>
    );
  };

  attachmentFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <span className="icons" title="Documents">
          <span
            className={'custom-link'}
            onClick={(event) => this.handleOpenAttachments(event, row, formatExtraData.formDetails)}
          >
            <FontAwesomeIcon icon={faPaperclip} className="icon-disp" />
            <span className="show-span">{row.attached ? row.attached : ''}</span>
          </span>
        </span>
      </>
    );
  };

  actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <div className="btn-group">
          <span
            className={'custom-link btn-dots'}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-testid={`table-three-dots-${rowIndex}`}
          >
            <FontAwesomeIcon className="icon-disp" icon={faEllipsisV} />
          </span>
          <div className="dropdown-menu dropdown-menu-right">
            {formatExtraData.map((actionItem, index) => {
              /* If the action is not function call, we will replace id with required value for the action href */
              /* Example: /sites/:id/edit will be changed to /sites/8/edit*/
              if (!actionItem.action.includes('functioncall')) {
                const action = actionItem.action.replace(':id', row[actionItem.params[0].value]);

                return (
                  <div
                    data-testid={actionItem.name + rowIndex}
                    className="dropdown-item"
                    key={index}
                    onClick={() => window.open(action)}
                    style={{ cursor: 'pointer' }}
                  >
                    {actionItem.name}
                  </div>
                );
              }
              let name = actionItem.name;
              if (actionItem.hasOwnProperty('value')) {
                name = actionItem.value;
              }
              let isAllowedDomain = true;
              if (name.toLowerCase() === 'email') {
                isAllowedDomain = domain_validation(row.user_email);
              }
              if (isAllowedDomain) {
                if (actionItem.isDisabled && actionItem.isDisabled(row)) {
                  return (
                    <span
                      key={index}
                      className="dropdown-item"
                      style={{ opacity: 0.3, cursor: 'not-allowed' }}
                      data-testid={actionItem.name + rowIndex}
                    >
                      {actionItem.name}
                    </span>
                  );
                }

                return (
                  <span
                    data-testid={actionItem.name + rowIndex}
                    className="dropdown-item custom-link"
                    onClick={(event) =>
                      this.handleRowAction(event, row, actionItem.formDetails, actionItem.displayValue, name)
                    }
                    key={index}
                  >
                    {actionItem.name}
                  </span>
                );
              }
              return null;
            })}
          </div>
        </div>
      </>
    );
  };

  timeConversionFormatter = (cell) => {
    return <>{moment(moment.utc(cell).toDate()).local().format('YYYY-MM-DD HH:mm:ss')}</>;
  };

  fetchFromObj = (cell, row, rowIndex, formatExtraData) => {
    const formFields = formatExtraData.formFields;
    const type = formatExtraData.type;
    const data = Array.isArray(cell) ? cell : [cell];

    const typeToResult = {
      primitiveArrayToString: () => {
        if (data.length >= 10) {
          return data.slice(0, 10).join(', ') + '...';
        }
        return data.join(', ');
      },
      arrayToString: () => {
        return data.reduce((acc, item, index) => {
          let reducedString = formFields.reduce((reducedFields, currentField) => {
            const split = currentField.split('.');

            if (split.length === 1) {
              reducedFields += item[split[0]];
            } else {
              reducedFields += item[split[0]][split[1]];
            }

            return reducedFields;
          }, '');

          if (index < cell.length - 1) {
            reducedString += ',';
          }

          acc += reducedString;
          return acc;
        }, '');
      },
      toString: () => {
        return data.reduce((acc, curr, i) => {
          acc += curr;

          if (i < data.length - 1) {
            acc += ', ';
          }

          return acc;
        });
      },
      formatBool: () => (cell ? 'Y' : 'N'),
    };

    return typeToResult[type]();
  };

  displayTextFormatter = (cell, row, rowIndex, formatExtraData) => {
    const formatter = formatExtraData.value;
    const option = this.state.fetch_list.find((item) => item.value === cell);
    const val = option[formatter];
    return (
      <>
        <span>{val}</span>
      </>
    );
  };

  handleFetchComponents = (item) => {
    this.setState({ ...this.state, selectedItem: item });
  };

  handleRowAction = (event, row, formDetails, displayValue, actionType) => {
    const { type } = this.props;
    let confirmType = null;
    let isConfirmDialogOpen = false;

    if (actionType.includes('delete') || actionType.includes('invite_to_')) {
      confirmType = actionType.toLowerCase();
      isConfirmDialogOpen = true;
    } else {
      this.props.onHandleAction(row, actionType, type);
    }

    this.setState({
      ...this.state,
      selectedRow: row,
      itemNameToConfirm: row[displayValue],
      confirmType,
      isConfirmDialogOpen,
    });
  };

  handleOpenAttachments = (event, row, formDetails) => {
    this.props.onHandleAction(row, 'attachment', formDetails);
  };

  handleChangePageSize = (event) => {
    this.props.onChangePageSize(event);
  };

  handleTableChange = async (
    changeType: SortType,
    { page, sizePerPage, sortField }: { page: number; sizePerPage: number; sortField: string }
  ) => {
    const { sortOrder } = this.props;

    // @TODO: once we remove the global config file, provide the config as a prop to this component
    const config = this.props.tableConfig || siteConfig[this.props.type].tableConfig;

    if (!sortField && config) {
      sortField = config.keyField;
    }

    type InverseSortOrderMap = {
      desc: 'asc';
      asc: 'desc';
    };

    const inverseSortOrderMap: InverseSortOrderMap = {
      desc: 'asc',
      asc: 'desc',
    };

    let updatedSortOrder = sortOrder; // Default value
    if (changeType === 'sort') updatedSortOrder = inverseSortOrderMap[sortOrder]; // If sort was toggled, use the inverse sort order

    this.setState({ selectedColumn: sortField, sortIconValue: updatedSortOrder });
    this.props.onTableChange({ changeType, page, sizePerPage, sortField, sortOrder: updatedSortOrder });
  };

  getSortIcon = (order, column) => {
    const { selectedColumn, sortIconValue } = this.state;
    let iconClass = 'sort';

    if (selectedColumn === column.dataField) {
      iconClass = sortIconValue === 'asc' ? 'sort-up' : 'sort-down';
    }

    return (
      <>
        <span>&nbsp;&nbsp;</span>
        <FontAwesomeIcon icon={iconClass as IconName} />
      </>
    );
  };

  handleExpandRow = (type, expandConfig, row, expandData, expandType) => {
    const { selectedItem } = this.state;
    const tableProps = {
      type,
      expandConfig,
      row,
      expandData,
      expandType,
      selectedItem,
      isFetchingExpandData: this.props.isFetchingExpandData,
      handleRowAction: this.handleRowAction,
      onHandleAction: this.props.onHandleAction,
      onExpandFetch: this.props.onExpandFetch,
      handleFetchComponents: this.handleFetchComponents,
      history: this.props.history,
    };

    return <NestedTableWrapper {...tableProps} />;
  };

  buildAndFormatColumns = () => {
    const { type, subType, tableConfig } = this.props;

    let config;

    if (tableConfig) {
      config = tableConfig;
    } else {
      config = siteConfig?.[type]?.tableConfig;

      if (subType) {
        config = config?.[subType];
      }
    }

    const { columns, keyField, expandConfig, expand } = config ?? DEFAULT_TABLE_CONFIG;

    if (!columns || !columns.length) {
      console.error(`Something went wrong getting columns for table type ${type}`);
    }

    columns.forEach((column) => {
      if (column.sort) {
        column.sortCaret = this.getSortIcon;
      }

      if (column.formatterType) {
        column.formatter = this.formatterMap[column.formatterType];
        column.formatExtraData = column.formatterDetails;
      }
    });

    return {
      columns,
      expandConfig,
      expand,
      keyField,
    };
  };

  getRowExpandConfig = (expand, expandConfig, keyField) => {
    const { data, expandFetchData, expandType, type } = this.props;

    if (expand && data?.length) {
      return {
        renderer: (row) => this.handleExpandRow(type, expandConfig, row, expandFetchData, expandType),
        //Leave the header blank
        expandColumnRenderer: ({ expanded, rowKey }) =>
          expanded ? (
            <CaretUpSquare data-testid={`collapse-row-btn-${rowKey}`} />
          ) : (
            <CaretDownSquare data-testid={`expand-row-btn-${rowKey}`} />
          ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        onlyOneExpanding: true,
        expanded: this.state.expandedRows,
        onExpand: async (row, isExpand) => {
          if (isExpand) {
            const expandedRows = [row[keyField]];
            this.setState({ expandedRows });

            this.props.onExpandFetch(expandType, row);
          } else {
            this.setState({ expandedRows: [] });
          }
        },
      };
    }

    return { renderer: () => <div></div> };
  };

  render() {
    const { data, page, sizePerPage, totalSize, remote, rowEvents, isRowExpandable = true } = this.props;
    const { selectedRow } = this.state;
    const { columns, expandConfig, expand, keyField } = this.buildAndFormatColumns();
    const expandProps = isRowExpandable && { expandRow: this.getRowExpandConfig(expand, expandConfig, keyField) };
    const PaginatedBootstrapTable = ({ data, page, sizePerPage, onTableChange, totalSize, rowEvents }) => (
      <div>
        <BootstrapTable
          remote={remote}
          keyField={keyField}
          data={data}
          columns={columns}
          rowEvents={rowEvents}
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'Next page',
            prePageTitle: 'Previous page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
          })}
          onTableChange={onTableChange}
          {...expandProps}
        />
      </div>
    );

    return (
      <div data-testid="bootstrap-table">
        {this.state.isConfirmDialogOpen && (
          <TableConfirmationBox
            onConfirmAction={(actionType) => {
              this.setState({
                ...this.state,
                isConfirmDialogOpen: false,
              });

              this.props.onHandleAction(selectedRow, actionType);
            }}
            onCancelAction={() => {
              this.setState({
                ...this.state,
                isConfirmDialogOpen: false,
              });
            }}
            confirmType={this.state.confirmType}
            itemName={this.state.itemNameToConfirm}
          />
        )}

        {!!columns.length && (
          <div>
            <PaginatedBootstrapTable
              data={data}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              rowEvents={rowEvents}
              onTableChange={this.handleTableChange}
            />

            <div className="float-left-custom">
              <select className="pageSizeList" value={sizePerPage} onChange={this.handleChangePageSize}>
                {PAGE_SIZES.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
      </div>
    );
  }

  // Links column types with formatting methods
  formatterMap = {
    ellipsis: this.ellipsisFormatter,
    attachment: this.attachmentFormatter,
    action: this.actionFormatter,
    displayText: this.displayTextFormatter,
    timeConversion: this.timeConversionFormatter,
    fetchFromObj: this.fetchFromObj,
    showSiteImage: this.props.showSiteImageFormatter,
  };
}

const DEFAULT_TABLE_CONFIG = {
  columns: [],
  expandConfig: {},
  keyField: '',
  expand: false,
};

const PAGE_SIZES = [
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 75, value: 75 },
  { label: 100, value: 100 },
];
