import { toZonedTime } from 'date-fns-tz';

/**
 * Converts an integer hour in 24-hour time to a 12-hour value.
 *
 * @param {number} hour Integer hour value ranging (0 <= hour <= 23)
 */
export function convertHourTo12HourTime(hour) {
  if (typeof hour !== 'number' || hour < 0 || hour > 23) {
    throw new Error(`Unable to convert value ${hour} to 12 hour format`);
  }

  let newTime = hour;

  if (newTime >= 12) {
    newTime -= 12;
  }

  if (newTime === 0) {
    newTime = 12;
  }

  return `${newTime}`;
}

/**
 * Calculates difference between the timestamp in minutes based on timezone.
 * If no end timestamp is provided, it uses current date's timestamp
 *
 * @param {string} timezone represents timezone value
 * @param {number} starTimestamp represents timestamp of starting date
 * @param {number} endTimestamp (optional) represents timestamp of ending date
 */
export function getMinuteDifferenceBetweenTimeStamps(
  timezone: string,
  startTimestamp: number,
  endTimestamp?: number
): number | null {
  if (!startTimestamp) return null;
  const startDate = toZonedTime(new Date(startTimestamp * 1000), timezone);
  const endDate = toZonedTime(endTimestamp ? new Date(endTimestamp * 1000) : new Date(), timezone);
  return Math.ceil((endDate.getTime() - startDate.getTime()) / 60000);
}
