import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Button } from '@chakra-ui/button';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Flex, Grid, Heading } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { FieldArrayWithId, useFieldArray, useWatch } from 'react-hook-form';
import { InstalledDevice } from '../../../api/api-device';
import { del, getTokenAndEmailFromSession } from '../../../common/api-utils';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import SearchableDropdownFormControl from '../../../components/SearchableDropdownFormControl';
import { useSiteDeviceContext } from '../../../context/SiteDeviceContext';
import { CommonFieldListProps, InverterSubarrayData, SiteDevicesFormData } from './site-devices-types';

export const EMPTY_SUBARRAY_TEMPLATE: InverterSubarrayData = {
  subArrayId: null,
  manufacturer: '',
  model: '',
  modulesPerString: 0,
  numOfStrings: 0,
  orientationDegrees: 0,
  tiltDegrees: 0,
};

type NestedSubarrayFieldArrayProps = {
  nestIndex: number;
} & CommonFieldListProps;

const NestedSubarrayFieldArray = (props: NestedSubarrayFieldArrayProps) => {
  const { nestIndex, register, control, errors, getValues } = props;

  const { fields, append, remove } = useFieldArray({
    name: `inverters.${nestIndex}.subArrays`,
    control,
  });
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);

  useEffect(() => {
    setExpandedIndex(fields.length - 1);
  }, [fields.length]);

  async function handleDeleteSubarray(subArrayId: number, index: number) {
    if (window.confirm('Are you sure you want to delete this subArray?')) {
      if (subArrayId) {
        try {
          const { jwtToken } = await getTokenAndEmailFromSession();
          await del<InstalledDevice>('sub_array', `/site/subarrays/${subArrayId}`, jwtToken);
        } catch (e) {
          displayAPIErrorMessage(e);
        }
      }

      remove(index);
    }
  }

  return (
    <Flex direction="column">
      <Accordion
        allowToggle
        index={expandedIndex}
        onChange={(clickedIndex: number) => setExpandedIndex(clickedIndex)}
        w={'100%'}
      >
        {fields.map((field: FieldArrayWithId<SiteDevicesFormData, `inverters.${number}.subArrays`>, index) => (
          <AccordionItem key={`${field.id}-${index}-${nestIndex}`}>
            <AccordionButton role={'button'} as={Box} py={4}>
              <Flex w={'100%'} justify={'space-between'} align={'center'}>
                <Heading>Subarray {index + 1}</Heading>
                <Flex align={'center'}>
                  <Button
                    variant={'ghost'}
                    onClick={() => handleDeleteSubarray(field.subArrayId, index)}
                    style={{ fontSize: '12px' }}
                    mr={2}
                    colorScheme="red"
                    aria-label="Delete subarray"
                  >
                    Remove
                  </Button>
                  <AccordionIcon w={8} h={8} mr={3} />
                </Flex>
              </Flex>
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Grid columnGap={10} templateColumns={`repeat(2, 1fr)`}>
                <SubArrayModelManufacturerFields
                  {...{ ...props, index: nestIndex, field, nestIndex: index, getValues }}
                />
                <FormControl
                  isInvalid={!!errors?.inverters?.[nestIndex]?.subArrays?.[index]?.modulesPerString}
                  mt={3}
                  id={`${field.id}_modulesPerString`}
                >
                  <FormLabel>Modules per string</FormLabel>
                  <Input
                    data-testid={`inverters-${nestIndex}-subArrays-${index}-modulesPerString`}
                    defaultValue={field.modulesPerString}
                    {...register(`inverters.${nestIndex}.subArrays.${index}.modulesPerString` as const)}
                    type="number"
                    step="0.00000000001"
                    padding="9px 13px !important"
                  />
                  <FormErrorMessage>
                    {errors?.inverters?.[nestIndex]?.subArrays?.[index]?.modulesPerString?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!errors?.inverters?.[nestIndex]?.subArrays?.[index]?.numOfStrings}
                  mt={3}
                  id={`${field.id}_numOfStrings`}
                >
                  <FormLabel>Number of strings</FormLabel>
                  <Input
                    data-testid={`inverters-${nestIndex}-subArrays-${index}-noOfStrings`}
                    defaultValue={field.numOfStrings}
                    {...register(`inverters.${nestIndex}.subArrays.${index}.numOfStrings` as const)}
                    type="number"
                    step="0.00000000001"
                    padding="9px 13px !important"
                  />
                  <FormErrorMessage>
                    {errors?.inverters?.[nestIndex]?.subArrays?.[index]?.numOfStrings?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!errors?.inverters?.[nestIndex]?.subArrays?.[index]?.orientationDegrees}
                  mt={3}
                  id={`${field.id}_orientationDegrees`}
                >
                  <FormLabel>Orientation (0-360°)</FormLabel>
                  <Input
                    data-testid={`inverters-${nestIndex}-subArrays-${index}-orientationDegrees`}
                    defaultValue={field.orientationDegrees}
                    {...register(`inverters.${nestIndex}.subArrays.${index}.orientationDegrees` as const)}
                    type="number"
                    step="0.00000000001"
                    padding="9px 13px !important"
                  />
                  <FormErrorMessage>
                    {errors?.inverters?.[nestIndex]?.subArrays?.[index]?.orientationDegrees?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!errors?.inverters?.[nestIndex]?.subArrays?.[index]?.tiltDegrees}
                  mt={3}
                  id={`${field.id}_tilt`}
                >
                  <FormLabel>Tilt (0-90°)</FormLabel>
                  <Input
                    data-testid={`inverters-${nestIndex}-subArrays-${index}-tiltDegrees`}
                    defaultValue={field.tiltDegrees}
                    {...register(`inverters.${nestIndex}.subArrays.${index}.tiltDegrees` as const)}
                    type="number"
                    step="0.00000000001"
                    padding="9px 13px !important"
                  />
                  <FormErrorMessage>
                    {errors?.inverters?.[nestIndex]?.subArrays?.[index]?.tiltDegrees?.message}
                  </FormErrorMessage>
                </FormControl>
              </Grid>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Button
        py={6}
        my={3}
        mr="auto"
        onClick={() => append(EMPTY_SUBARRAY_TEMPLATE)}
        type="button"
        variant="ghost"
        color="clipsalGreen.500"
      >
        + Add SubArray {fields.length + 1 || 1}
      </Button>
    </Flex>
  );
};

export default NestedSubarrayFieldArray;

type SubArrayModelManufacturerFieldPairProps = {
  index: number;
  field: FieldArrayWithId<SiteDevicesFormData, `inverters.${number}.subArrays`>;
  nestIndex: number;
} & CommonFieldListProps;

const SubArrayModelManufacturerFields = (props: SubArrayModelManufacturerFieldPairProps) => {
  const { errors, index, field, setValue, control, nestIndex, getValues } = props;
  const {
    manufacturers,
    modelsByManufacturerId,
    isManufacturerLoaded,
    isFetchingNewModels,
    fetchModels,
    fetchManufacturersList,
  } = useSiteDeviceContext();

  const { inverters } = useWatch({ control });
  const selectedManufacturer = inverters?.[index].subArrays?.[nestIndex].manufacturer;
  const isModelSelectDisabled =
    selectedManufacturer && !modelsByManufacturerId?.['SOLAR_MODULE']?.[selectedManufacturer.toString()]?.length;
  const errorsAtIndex = errors?.inverters?.[index]?.subArrays?.[nestIndex];
  const valuesAtIndex = getValues()?.inverters?.[index]?.subArrays?.[nestIndex];

  function getModelSelectText() {
    if (!selectedManufacturer) return 'Select a manufacturer to view models.';
    else if (isFetchingNewModels) return 'Loading...';
    else if (modelsByManufacturerId?.['SOLAR_MODULE']?.[selectedManufacturer.toString()]?.length === 0)
      return 'No models for this manufacturer.';

    return 'Select a model...';
  }

  useEffect(() => {
    fetchManufacturersList('SOLAR_MODULE');
  }, []);

  useEffect(() => {
    if (selectedManufacturer && isManufacturerLoaded) {
      fetchModels(selectedManufacturer, 'SOLAR_MODULE');
    }
  }, [selectedManufacturer, isManufacturerLoaded]);

  return (
    <>
      <FormControl isInvalid={!!errorsAtIndex?.manufacturer} mt={3} id={`${field.id}_manufacturer`} mr={5}>
        <FormLabel>Manufacturer</FormLabel>
        <SearchableDropdownFormControl
          data-testid={`select-subarray-manufacturer-${index}`}
          defaultValue={valuesAtIndex.manufacturer && Number(valuesAtIndex.manufacturer)}
          control={control}
          options={manufacturers['SOLAR_MODULE']}
          valueLabelOptions={['manufacturer_id', 'manufacturer_name']}
          fieldName={`inverters.${index}.subArrays.${nestIndex}.manufacturer`}
          placeholder={manufacturers['SOLAR_MODULE'] ? 'Select a manufacturer...' : 'Loading...'}
          onDependentFieldAndStateUpdate={() => setValue(`inverters.${index}.subArrays.${nestIndex}.model` as any, '')}
          isInvalid={!!errorsAtIndex?.manufacturer}
          isDisabled={isFetchingNewModels}
        />

        <FormErrorMessage>{errorsAtIndex?.manufacturer?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errorsAtIndex?.model} mt={3} id={`${field.id}_model`}>
        <FormLabel>Model</FormLabel>
        <SearchableDropdownFormControl
          data-testid={`select-subarray-model-${index}`}
          isDisabled={!selectedManufacturer || isModelSelectDisabled || !isManufacturerLoaded}
          defaultValue={valuesAtIndex.model && Number(valuesAtIndex.model)}
          control={control}
          options={modelsByManufacturerId['SOLAR_MODULE']?.[selectedManufacturer] || []}
          valueLabelOptions={['manufacturer_id', 'manufacturer_name']}
          fieldName={`inverters.${index}.subArrays.${nestIndex}.model`}
          placeholder={getModelSelectText()}
          isInvalid={!!errorsAtIndex?.model}
        />
        <FormErrorMessage>{errorsAtIndex?.model?.message}</FormErrorMessage>
      </FormControl>
    </>
  );
};
