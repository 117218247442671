const siteConfig = {
  general: {
    google_api: 'AIzaSyCcEVsIFyIZdh9Orw2jwcGvMXVrO2EjYG8',
    season_values: [
      { value: '01', label: 'JAN' },
      { value: '02', label: 'FEB' },
      { value: '03', label: 'MAR' },
      { value: '04', label: 'APR' },
      { value: '05', label: 'MAY' },
      { value: '06', label: 'JUN' },
      { value: '07', label: 'JUL' },
      { value: '08', label: 'AUG' },
      { value: '09', label: 'SEP' },
      { value: '10', label: 'OCT' },
      { value: '11', label: 'NOV' },
      { value: '12', label: 'DEC' },
    ],
    metabase: {
      baseurl: 'https://reports.clipsalsolar.com',
      token: '0e6676e9c2347243d740e6928c8c3b016ff06c47038084cd7abc887ac8cfc484',
    },
    notification_timer: 2,
    ideal_time: 30,
    sizePerList: [
      { label: 10, value: 10 },
      { label: 25, value: 25 },
      { label: 50, value: 50 },
      { label: 75, value: 75 },
      { label: 100, value: 100 },
    ],
    match: {
      date: [
        { label: '==', value: '=' },
        { label: '>', value: '>' },
        { label: '>=', value: '>=' },
        { label: '<', value: '<' },
        { label: '<=', value: '<=' },
      ],
      int: [
        { label: '==', value: '=' },
        { label: '>', value: '>' },
        { label: '>=', value: '>=' },
        { label: '<', value: '<' },
        { label: '<=', value: '<=' },
      ],
    },
  },
  headers: {
    menu: [{ label: 'Sites', name: 'sites', href: '#/sites', roles: ['SUPER_ADMIN'] }],
  },
  sidebar: {
    topmenu: [
      { label: 'Add Site', name: 'site', href: '#/sites/add', roles: ['SUPER_ADMIN'] },
      {
        label: 'Administration',
        name: 'Adminstration',
        href: '#',
        roles: ['SUPER_ADMIN'],
        list: [
          { label: 'Data Correction', name: 'data_correction', href: '#/data_correction', roles: ['SUPER_ADMIN'] },
          {
            label: 'Wattwatchers Meter Configuration',
            name: 'wattwatchers_meter_configuration',
            href: '#/wattwatchers_meter_configuration',
            roles: ['SUPER_ADMIN'],
          },
          { label: 'Articles', name: 'articles', href: '#/articles', roles: ['SUPER_ADMIN'] },
          { label: 'Tenants', name: 'tenants', href: '#/tenants', roles: ['SUPER_ADMIN'] },
        ],
      },
    ],
  },
  site: {
    site_rooftypes: [
      { label: 'Tile', value: 'tile' },
      { label: 'Tin', value: 'tin' },
    ],
    electrical_configs: [
      { label: '1Ph', value: '1ph' },
      { label: '2Ph', value: '2ph' },
      { label: '3Ph', value: '3ph' },
    ],
    tableConfig: {
      keyField: 'clipsal_solar_id',
      expand: true,
      columns: [
        { dataField: 'tenant.tenant_name', text: 'Fleet', sort: true, data_type: 'string' },
        {
          dataField: 'clipsal_solar_id',
          text: 'Site ID',
          sort: true,
          data_type: 'int',
          formatterType: 'showSiteImage',
          formatterDetails: {
            fromfield: 'clipsal_solar_id',
          },
        },
        { dataField: 'site_name', text: 'Site Name', sort: true, data_type: 'string' },
        { dataField: 'site_city', text: 'City', sort: true, data_type: 'string' },
        { dataField: 'site_state', text: 'State', sort: true, data_type: 'string' },
        {
          dataField: 'users',
          text: 'Associated Users',
          data_type: 'string',
          formatterType: 'fetchFromObj',
          formatterDetails: { formFields: ['user_full_name'], type: 'arrayToString' },
        },
        {
          dataField: 'action',
          text: 'Action',
          formatterType: 'action',
          formatterDetails: [
            {
              name: 'Associate Device',
              action: '#/sites/:id/edit/device',
              params: [{ key: 'site', value: 'clipsal_solar_id' }],
            },
            {
              name: 'Associate User',
              action: '#/sites/:id/edit/user',
              params: [{ key: 'site', value: 'clipsal_solar_id' }],
            },
            { name: 'Edit', action: '#/sites/:id/edit', params: [{ key: 'site', value: 'clipsal_solar_id' }] },
            {
              name: 'Delete',
              value: 'delete_site',
              action: 'functioncall',
              displayValue: 'site_name',
              messageNeeded: true,
            },
            {
              name: 'View in Cortex',
              value: 'view_in_pulse',
              action: 'functioncall',
            },
            {
              name: 'Manage Bills',
              value: 'add_bill',
              action: 'functioncall',
            },
            {
              name: 'Refresh Circuit Data',
              value: 'refresh_circuits',
              action: 'functioncall',
            },
            {
              name: 'Refresh Site Image',
              value: 'refresh_image',
              action: 'functioncall',
            },
          ],
        },
      ],
      expandConfig: {
        tablist: [
          { label: 'Meter', value: 'meter' },
          { label: 'Inverter', value: 'inverter' },
          { label: 'Battery', value: 'battery_pack' },
          { label: 'Solar Module', value: 'solar_module' },
          { label: 'EV Charger', value: 'ev_charger' },
          { label: 'Investment', value: 'investments' },
          { label: 'Bills', value: 'bills' },
        ],
        sitetype: {
          meter: [
            { label: 'Manufacturer', value: 'manufacturer' },
            { label: 'Model', value: 'model' },
            { label: 'Options', value: 'options' },
            { label: 'Version', value: 'version' },
            { label: 'Amps', value: 'Amps' },
            { label: 'Fault Code', value: 'fault_code' },
            { label: 'Site Identifier', value: 'site_identifier' },
            { label: 'Monitors', value: 'Functioncall', type: 'monitorfetch' },
          ],
          inverter: [
            { label: 'Manufacturer', value: 'manufacturer' },
            { label: 'Model', value: 'model' },
            { label: 'Options', value: 'options' },
            { label: 'Version', value: 'version' },
            { label: 'DERTyp', value: 'DERTyp' },
            { label: 'AC Output Power(W)', value: 'ac_power' },
            { label: 'Operating Status', value: 'operating_state' },
            { label: 'Fault Code', value: 'fault_code' },
            { label: 'Site Identifier', value: 'site_identifier' },
            { label: 'Hybrid Inverter', value: 'is_hybrid_inverter' },
            { label: 'SubArray', value: 'functioncall', type: 'sub_array' },
          ],
          battery_pack: [
            { label: 'Manufacturer', value: 'manufacturer' },
            { label: 'Model', value: 'model' },
            { label: 'Options', value: 'options' },
            { label: 'Version', value: 'version' },
            { label: 'Energy Capacity', value: 'battery_pack_w_h_rtg' },
            { label: 'State of Charge', value: 'battery_pack_s_o_c' },
            { label: 'Voltage', value: 'battery_pack_v' },
            { label: 'Amps', value: 'battery_pack_a' },
            { label: 'Fault Code', value: 'fault_code' },
            { label: 'Site Identifier', value: 'site_identifier' },
          ],
          solar_module: [
            { label: 'Manufacturer', value: 'manufacturer' },
            { label: 'Model', value: 'model' },
            { label: 'Options', value: 'options' },
            { label: 'Version', value: 'version' },
            { label: 'Power Capacity', value: 'solar_wrtg' },
            { label: 'Quantity', value: 'quantity' },
            { label: 'Installed Capacity', value: 'solar_installed_capacity' },
            { label: 'Site Identifier', value: 'site_identifier' },
          ],
          investments: [
            { label: 'Investment Type', value: 'investment_type' },
            { label: 'Description', type: 'infofetch', value: 'functionCall', subtype: 'description' },
            { label: 'Installation Date', value: 'installation_date' },
            { label: 'No of Payments', value: 'no_of_payments' },
            { label: 'Billing Period', value: 'billing_period' },
            { label: 'Amount', value: 'amount' },
          ],
          insight_history: [
            { label: 'Datetime', value: 'datetime' },
            { label: 'Name', value: 'name' },
            { label: 'Text', value: 'text' },
          ],
          bills: [
            { label: 'Source', value: 'source' },
            { label: 'Start Date', value: 'start_date' },
            { label: 'End Date', value: 'end_date' },
            { label: 'Retailer', value: 'retailer_name' },
            { label: 'Type', value: 'type' },

            { label: 'Total Bill', value: 'total_amount', tofix: 2 },
            { label: 'Currency', value: 'currency' },
            { label: 'Average Daily Consumption (kWh)', value: 'consumption', tofix: 2 },
            {
              label: 'Action',
              value: 'functioncall',
              type: 'action',
              list: [
                { display: true, type: 'bill_view' },
                {
                  type: 'delete_bill',
                  dependsOn: 'source',
                  match_val: 'manual bill',
                  displayValue: 'retailer_name',
                  messageNeeded: true,
                  display: true,
                },
              ],
            },
          ],
        },
      },
    },
    details: {
      faultcode: {
        keyField: 'row_id',
        columns: [
          { text: 'Device type', dataField: 'device_type' },
          { text: 'Manufacturer', dataField: 'manufacturer' },
          { text: 'Model', dataField: 'model' },
          { text: 'Site Identifier', dataField: 'site_identifier' },
          { text: 'Operating State', dataField: 'operating_state' },
          { text: 'Fault Code', dataField: 'fault_code' },
        ],
      },
    },
    search_other_component: {
      list_circuits: {
        columns: [
          {
            dataField: 'clipsal_monitors',
            text: 'Monitor',
            sort: true,
            data_type: 'string',
            inline_edit: true,
            inline_edit_type: 'dboptions',
          },
          { dataField: 'oem_circuit_id', text: 'Circuit ID', sort: true, data_type: 'string' },
          { dataField: 'circuit_name', text: 'Circuit Name', sort: true, data_type: 'string' },
          {
            dataField: 'controlled_load_yn',
            text: 'Controlled Load',
            sort: true,
            data_type: 'string',
            inline_edit: true,
            inline_edit_type: 'options',
          },
          {
            dataField: 'controlled_load2_yn',
            text: 'Controlled Load 2',
            sort: true,
            data_type: 'string',
            inline_edit: true,
            inline_edit_type: 'options',
          },
        ],
      },
    },
    investment: [
      {
        label: 'Investment Type',
        value: 'investment_type',
        type: 'select',
        fetchtype: 'manual',
        list: [
          { label: 'Single', value: 'SINGLE' },
          { label: 'Recurring', value: 'RECURRING' },
        ],
        display: null,
        unmatched: '',
      },
      // ,{label:"Description",value:"description",type:"select",placeholder:"Enter Description",fetchtype:"manual",unmatched:{label:'Other - Enter Description',value:'other'},list:[{"label":"Clipsal Pulse","value":"Clipsal Pulse"},{"label":"Solar PV & Clipsal Pulse","value":"Solar PV & Clipsal Pulse"},{"label":"Solar PV with Energy Storage & Clipsal Pulse","value":"Solar PV with Energy Storage & Clipsal Pulse"},{"label":"Energy Storage","value":"Energy Storage"},{"label":"Solar PV Upgrade","value":"Solar PV Upgrade"},{"label":"Switchboard Upgrade","value":"Switchboard Upgrade"},{label:'Other - Enter Description',value:'other'}]}
      { label: 'Description', value: 'products', type: 'popup' },
      { label: 'Installation Date', value: 'installation_date', type: 'date', placeholder: 'Enter Install Date' },
      {
        label: 'No of Payments',
        value: 'no_of_payments',
        placeholder: 'Enter No of Payments',
        validation: {
          dependsOn: 'investment_type',
          val: { label: 'Single', value: 'SINGLE' },
          disable: true,
          defaultvalue: 1,
        },
      },
      {
        label: 'Billing Period',
        value: 'billing_period',
        type: 'select',
        fetchtype: 'manual',
        list: [
          { label: 'Single', value: 'SINGLE' },
          { label: 'Fortnight', value: 'FORTNIGHT' },
          { label: 'Monthly', value: 'MONTHLY' },
          { label: 'Annual', value: 'ANNUAL' },
        ],
        validation: {
          dependsOn: 'investment_type',
          val: { label: 'Single', value: 'SINGLE' },
          disable: true,
          defaultvalue: { label: 'Single', value: 'SINGLE' },
        },
      },
      { label: 'Amount', value: 'amount', placeholder: 'Enter Amount' },
    ],
    investment_mapping: [
      { label: 'Despription', value: 'product_id', type: 'select', fetchtype: 'dynamic', fetchFrom: 'products' },
      { label: 'Size', value: 'size', type: 'text' },
    ],
    validation: {
      associateDevice: {
        basicinfo: [
          { value: 'device_type', label: 'Device type', type: 'string', required: true },
          { value: 'manufacturer', label: 'Device Manufacturer', type: 'string', required: true },
          { value: 'site_identifier', label: 'Site Identifier', type: 'string', required: false },
          { value: 'api_key', label: 'API key', type: 'string', required: false },
          { value: 'quantity', label: 'Quantity', type: 'string', required: true },
        ],
      },
      addinvestment: {
        basicinfo: [
          { value: 'investment_type', label: 'Investment Type', type: 'string', required: true },
          { value: 'description', label: 'Description', type: 'string', required: false },
          { value: 'installation_date', label: 'Installation Date', type: 'date', required: true },
          { value: 'no_of_payments', label: 'No of Payments', type: 'string', required: true },
          { value: 'billing_period', label: 'Billing Period', type: 'string', required: true },
          { value: 'amount', label: 'Amount', type: 'string', required: true },
        ],
      },
      addinvestmentmapping: {
        basicinfo: [
          { value: 'product_id', label: 'description ', type: 'string', required: true },
          { value: 'size', label: ' Size', type: 'string', required: false },
        ],
      },
    },

    devices: {
      meter: {
        solaranalytics: [
          { dataField: 'oem_circuit_id', text: 'Circuit Id' },
          { dataField: 'circuit_name', text: 'Circuit Name' },
          { dataField: 'monitors', text: 'Monitors' },
          { dataField: 'watt_device_id', text: 'Watt Device Id' },
          { dataField: 'apparent_power', text: 'Apparent Power' },
          { dataField: 'current_metric', text: 'Current Metric' },
          { dataField: 'energy', text: 'Energy' },
          { dataField: 'energy_neg', text: 'Energy Neg' },
          { dataField: 'energy_pos', text: 'Energy Pos' },
          { dataField: 'power_metric', text: 'Power Metric' },
          { dataField: 'power_factor', text: 'Power Factor' },
          { dataField: 'reactive_energy', text: 'Reactive Energy' },
          { dataField: 'voltage', text: 'Voltage' },
          { dataField: 'reading_datetime', text: 'Datetime' },
        ],
      },
      inverter: {
        solaredge: [
          { dataField: 'site_id', text: 'Site Id' },
          { dataField: 'total_active_power', text: 'Total Active Power' },
          { dataField: 'dc_voltage', text: 'DC Voltage' },
          { dataField: 'total_energy', text: 'Total Energy' },
          { dataField: 'temperature', text: 'Temperature' },
          { dataField: 'inverter_mode', text: 'Inverter Mode' },
          { dataField: 'ac_current', text: 'AC Current' },
          { dataField: 'ac_frequency', text: 'AC Frequency' },
          { dataField: 'ac_voltage', text: 'AC Voltage' },
          { dataField: 'active_power', text: 'Active Power' },
          { dataField: 'reactive_power', text: 'Reactive Power' },
          { dataField: 'ground_fault_resistance', text: 'Ground Fault Resistance' },
          { dataField: 'cosphi', text: 'Cosphi' },
          { dataField: 'reading_datetime', text: 'Datetime' },
        ],
        sungrow: [
          { dataField: 'site_identifier', text: 'Site Id' },
          { dataField: 'total_active_power', text: 'Total Active Power' },
          { dataField: 'reactive_power', text: 'Reactive Power' },
          { dataField: 'dc_voltage1', text: 'DC Voltage 1' },
          { dataField: 'dc_voltage2', text: 'DC Voltage 2' },
          { dataField: 'grid_frquency', text: 'Grid Frequency' },
          { dataField: 'a_phase_voltage', text: 'A Phase Voltage' },
          { dataField: 'a_phase_voltage', text: 'A Phase Current' },
          { dataField: 'dc_current1', text: 'DC Current1' },
          { dataField: 'dc_current2', text: 'DC Current2' },
          { dataField: 'total_generation', text: 'Total Generation' },
          { dataField: 'reading_datetime', text: 'Datetime' },
        ],

        fronius: [
          { dataField: 'MPPEnergy1', text: 'pv1_power' },
          { dataField: 'MPPEnergy2', text: 'pv2_power' },
          { dataField: 'IACMeanL1', text: 'a_phase_current' },
          { dataField: 'IACMeanL2', text: 'b_phase_current' },
          { dataField: 'IACMeanL3', text: 'c_phase_current' },
          { dataField: 'IDCMean', text: 'dc_current1' },
          { dataField: 'IDCMean2', text: 'dc_current2' },
          { dataField: 'UACMeanL1', text: 'a_phase_voltage' },
          { dataField: 'UACMeanL2', text: 'b_phase_voltage' },
          { dataField: 'UACMeanL3', text: 'c_phase_voltage' },
          { dataField: 'UACMeanLine1', text: 'ab_line_voltage' },
          { dataField: 'UACMeanLine2', text: 'bc_line_voltage' },
          { dataField: 'UACMeanLine3', text: 'ca_line_voltage' },
          { dataField: 'UDCMean', text: 'dc_voltage1' },
          { dataField: 'UDCMean2', text: 'dc_voltage2' },
          { dataField: 'InverterTempMean', text: 'temperature' },
          { dataField: 'FromGenToSomewhere', text: 'total_active_power' },
          { dataField: 'DevWork', text: 'DevWork' },
          { dataField: 'BattOperatingState', text: 'BattOperatingState' },
          { dataField: 'BattCellStatus', text: 'BattCellStatus' },
          { dataField: 'BattWarningCode', text: 'BattWarningCode' },
          { dataField: 'BattOperatingState', text: 'BattOperatingState' },
          { dataField: 'timestamp', text: 'Date Time' },
        ],
      },
    },
  },
  user: {
    validation: {
      addUser: {
        basicinfo: [
          { value: 'user_name', label: 'User Name', type: 'string', required: true },
          { value: 'role', label: 'User Role', type: 'string', required: true },
          { value: 'user_first_name', label: 'First Name', type: 'string', required: true },
          { value: 'user_last_name', label: 'User last Name', type: 'string', required: false },
          { value: 'user_email', label: 'Email', type: 'email', required: true },
          { value: 'user_phone', label: 'Phone', type: 'phone', required: false },
        ],
      },
    },
    tableConfig: {
      keyField: 'user_id',
      expand: true,
      columns: [
        { dataField: 'user_id', text: 'User ID', sort: true, data_type: 'string' },
        { dataField: 'user_full_name', text: 'Full Name', sort: true, data_type: 'string' },
        { dataField: 'user_email', text: 'Email', sort: true, data_type: 'string' },
        { dataField: 'user_phone', text: 'Phone', sort: true, data_type: 'string' },
        { dataField: 'role', text: 'Role', data_type: 'string' },
        {
          dataField: 'action',
          text: 'Action',
          formatterType: 'action',
          formatterDetails: [
            { name: 'Edit', action: '#/user:1', params: [{ key: 'user', value: 'user_id' }] },
            {
              name: 'Delete',
              value: 'delete_user',
              action: 'functioncall',
              displayValue: 'user_full_name',
              messageNeeded: true,
            },
            {
              name: 'Invite to Cortex',
              value: 'invite_to_pulse',
              action: 'functioncall',
              displayValue: 'user_full_name',
              messageNeeded: true,
            },
          ],
        },
      ],
      expandConfig: {
        otherList: [
          { label: 'User Name', value: 'user_name' },
          { label: 'Create On', value: 'row_created' },
          { label: 'Updated On', value: 'row_updated' },
        ],
      },
    },
    domainallowed: ['clipsalsolar.com', 'nirman.io'],
  },
  device: {
    validation: {
      addDevice: {
        basicinfo: [
          { value: 'deviceType', label: 'Device Type', type: 'string', required: true },
          { value: 'manufacturer', label: 'Manufacturer', type: 'string', required: true },
          { value: 'model', label: 'Model', type: 'string', required: true },
          { value: 'options', label: 'Options', type: 'string', required: false },
          { value: 'version', label: 'Version', type: 'string', required: false },
        ],
        INVERTER: [
          { value: 'inverter_der_type_id', label: 'DERType', type: 'string', required: true },
          { value: 'inverter_wrtg', label: 'WRTg', type: 'string', required: true },
          { value: 'inverter_artg', label: 'ARTg', type: 'string', required: true },
        ],
        METER: [{ value: 'meter_a', label: 'Amps', type: 'string', required: true }],
        BATTERY_PACK: [
          { value: 'battery_pack_w_h_rtg', label: 'WHRtg', type: 'string', required: true },
          { value: 'battery_pack_w_cha_rte_max', label: 'WChaRteMax', type: 'string', required: true },
          { value: 'battery_pack_w_dis_cha_rte_max', label: 'WDisChaRteMax', type: 'string', required: true },
        ],
        SOLAR_MODULE: [{ value: 'solar_wrtg', label: 'WRtg', type: 'string', required: true }],
      },
    },
    tabList: [
      { name: 'Inverters', value: 'INVERTER', key: 'inverter_id' },
      { name: 'Meters', value: 'METER', key: 'meter_id' },
      { name: 'Batteries', value: 'BATTERY_PACK', key: 'battery_pack_id' },
      { name: 'Solar Modules', value: 'SOLAR_MODULE', key: 'solar_id' },
    ],
    tableConfig: {
      INVERTER: {
        keyField: 'inverter_id',
        columns: [
          { dataField: 'inverter_id', text: 'Inverter ID', sort: true, data_type: 'int' },
          {
            dataField: 'device_metadata',
            text: 'Manufacturer',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['manufacturer'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Model',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['model'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Options',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['options'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Version',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['version'], type: 'arrayToString' },
          },
          {
            dataField: 'reference_info',
            text: 'DERTyp',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['list_of_value_name'], type: 'arrayToString' },
          },
          {
            dataField: 'installation_count',
            text: 'No. Of Installations',
            data_type: 'int',
            sort: true,
            formatterType: 'link',
            formatterDetails: 'inverter_id',
          },
          {
            dataField: 'action',
            text: 'Action',
            formatterType: 'action',
            formatterDetails: [
              {
                name: 'Edit',
                action: '#/devices/:id/edit',
                params: [
                  { key: 'device', value: 'inverter_id' },
                  { key: 'type', value: 'device_metadata.device_type' },
                ],
              },
              { name: 'delete_inverter', action: 'functioncall', displayValue: 'inverter_id', messageNeeded: true },
            ],
          },
        ],
      },
      METER: {
        keyField: 'meter_id',
        columns: [
          { dataField: 'meter_id', text: 'Meter ID', sort: true, data_type: 'int' },
          {
            dataField: 'device_metadata',
            text: 'Manufacturer',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['manufacturer'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Model',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['model'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Options',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['options'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Version',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['version'], type: 'arrayToString' },
          },
          { dataField: 'meter_a', text: 'Amps (A)', sort: true, data_type: 'int' },
          {
            dataField: 'installation_count',
            text: 'No. Of Installations',
            data_type: 'int',
            sort: true,
            formatterType: 'link',
            formatterDetails: 'meter_id',
          },
          {
            dataField: 'action',
            text: 'Action',
            formatterType: 'action',
            formatterDetails: [
              {
                name: 'Edit',
                action: '#/device:1/type:2',
                params: [
                  { key: 'device', value: 'meter_id' },
                  { key: 'type', value: 'device_metadata.device_type' },
                ],
              },
              { name: 'delete_meter', action: 'functioncall', displayValue: 'meter_id', messageNeeded: true },
            ],
          },
        ],
      },
      BATTERY_PACK: {
        keyField: 'battery_pack_id',
        columns: [
          { dataField: 'battery_pack_id', text: 'Battery Pack ID', sort: true, data_type: 'int' },
          {
            dataField: 'device_metadata',
            text: 'Manufacturer',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['manufacturer'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Model',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['model'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Options',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['options'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Version',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['version'], type: 'arrayToString' },
          },
          { dataField: 'battery_pack_w_h_rtg', text: 'Energy Capacity (W)', sort: true, data_type: 'int' },
          {
            dataField: 'installation_count',
            text: 'No. Of Installations',
            data_type: 'int',
            sort: true,
            formatterType: 'link',
            formatterDetails: 'battery_pack_id',
          },
          {
            dataField: 'action',
            text: 'Action',
            formatterType: 'action',
            formatterDetails: [
              {
                name: 'Edit',
                action: '#/device:1/type:2',
                params: [
                  { key: 'device', value: 'battery_pack_id' },
                  { key: 'type', value: 'device_metadata.device_type' },
                ],
              },
              { name: 'delete_battery', action: 'functioncall', displayValue: 'battery_pack_id', messageNeeded: true },
            ],
          },
        ],
      },
      SOLAR_MODULE: {
        keyField: 'solar_id',
        columns: [
          { dataField: 'solar_id', text: 'Solar ID', sort: true, data_type: 'int' },
          {
            dataField: 'device_metadata',
            text: 'Manufacturer',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['manufacturer'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Model',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['model'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Options',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['options'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Version',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['version'], type: 'arrayToString' },
          },
          { dataField: 'solar_wrtg', text: 'Power Capacity (W)', sort: true, data_type: 'int' },
          {
            dataField: 'installation_count',
            text: 'No. Of Installations',
            data_type: 'int',
            sort: true,
            formatterType: 'link',
            formatterDetails: 'solar_id',
          },
          {
            dataField: 'action',
            text: 'Action',
            formatterType: 'action',
            formatterDetails: [
              {
                name: 'Edit',
                action: '#/device:1/type:2',
                params: [
                  { key: 'device', value: 'solar_id' },
                  { key: 'type', value: 'device_metadata.device_type' },
                ],
              },
              { name: 'delete_solar_module', action: 'functioncall', displayValue: 'solar_id', messageNeeded: true },
            ],
          },
        ],
      },
      SENSOR: {
        keyField: 'sensor_id',
        columns: [
          { dataField: 'sensor_id', text: 'Sensor ID', sort: true, data_type: 'int' },
          {
            dataField: 'device_metadata',
            text: 'Manufacturer',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['manufacturer'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Model',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['model'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Options',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['options'], type: 'arrayToString' },
          },
          {
            dataField: 'device_metadata',
            text: 'Version',
            sort: true,
            data_type: 'string',
            formatterType: 'fetchFromObj',
            formatterDetails: { formFields: ['version'], type: 'arrayToString' },
          },
          { dataField: 'device_type', text: 'Sensor Type', sort: true, data_type: 'string' },
          {
            dataField: 'installation_count',
            text: 'No. Of Installations',
            data_type: 'int',
            sort: true,
            formatterType: 'link',
            formatterDetails: 'sensor_id',
          },
          {
            dataField: 'action',
            text: 'Action',
            formatterType: 'action',
            formatterDetails: [
              {
                name: 'Edit',
                action: '#/device:1/type:2',
                params: [
                  { key: 'device', value: 'sensor_id' },
                  { key: 'type', value: 'device_metadata.device_type' },
                ],
              },
              { name: 'delete_sensor', action: 'functioncall', displayValue: 'sensor_id', messageNeeded: true },
            ],
          },
        ],
      },
    },
  },
  devicedata: {
    type: [
      { label: 'meter', value: 'meter' },
      { label: 'inverter', value: 'inverter' },
    ],
    meter: {
      manufacturer: [
        {
          label: 'Solar Analytics',
          value: 'Solar Analytics',
          target_table: 'tgt_fact_solar_site_reading',
          target_column: 'reading_datetime',
          condition: [
            { column_name: 'site_id' },
            { column_name: 'oem_circuit_id' },
            { column_name: 'reading_datetime' },
          ],
          keyField: 'site_id',
          columns: [
            {
              dataField: 'oem_circuit_id',
              text: 'Circuit ID',
              data_type: 'int',
              editable: true,
              field_type: 'number',
              map_col: 'circuit',
            },
            {
              dataField: 'watt_device_id',
              text: 'Watt Device ID',
              data_type: 'string',
              editable: true,
              field_type: 'input',
              map_col: 'watt_device_id',
            },
            {
              dataField: 'monitors',
              text: 'Monitors',
              data_type: 'string',
              editable: true,
              field_type: 'input',
              map_col: 'monitors',
            },
            {
              dataField: 'apparent_power',
              text: 'Apparent Power',
              data_type: 'double',
              editable: false,
              field_type: 'number',
              map_col: 'apparentPower',
            },
            {
              dataField: 'reactive_power',
              text: 'Reactive Power',
              data_type: 'int',
              editable: false,
              field_type: 'number',
              map_col: 'reactivePower',
            },
            {
              dataField: 'power_factor',
              text: 'Power Factor',
              data_type: 'int',
              editable: false,
              field_type: 'number',
              map_col: 'powerFactor',
            },
            {
              dataField: 'polarity',
              text: 'Polarity',
              data_type: 'int',
              editable: false,
              field_type: 'number',
              map_col: 'polarity',
            },
            {
              dataField: 'circuit_name',
              text: 'Circuit Name',
              data_type: 'string',
              editable: true,
              field_type: 'input',
              map_col: 'circuit_name',
            },
            {
              dataField: 'power_metric',
              text: 'Power Metric',
              data_type: 'int',
              editable: false,
              field_type: 'number',
              map_col: 'power',
            },
            {
              dataField: 'reactive_energy',
              text: 'Reactive Energy',
              data_type: 'int',
              editable: false,
              field_type: 'number',
              map_col: 'reactiveEnergy',
            },
            {
              dataField: 'energy_pos',
              text: 'Energy POS',
              data_type: 'int',
              editable: false,
              field_type: 'number',
              map_col: 'energyPos',
            },
            {
              dataField: 'energy_neg',
              text: 'Energy NEG',
              data_type: 'int',
              editable: false,
              field_type: 'number',
              map_col: 'energyNeg',
            },
            {
              dataField: 'energy',
              text: 'Energy',
              data_type: 'int',
              editable: false,
              field_type: 'number',
              map_col: 'energy',
            },
            {
              dataField: 'voltage',
              text: 'Voltage',
              data_type: 'int',
              editable: false,
              field_type: 'number',
              map_col: 'voltage',
            },
            {
              dataField: 'current_metric',
              text: 'Current Metric',
              data_type: 'int',
              editable: false,
              field_type: 'number',
              map_col: 'current',
            },
            {
              dataField: 'reading_datetime',
              text: 'Reading Datetime',
              data_type: 'datetime',
              editable: true,
              field_type: 'input',
              map_col: 'time',
            },
            {
              dataField: 'action',
              text: 'Action',
              formatterType: 'action',
              formatterDetails: [
                {
                  name: 'Edit',
                  action: 'functioncall',
                  not_displayon: [{ key: 'fetchtype', value: 'api' }],
                  displayValue: 'site_id',
                  messageNeeded: false,
                  params: [{ key: 'site', value: 'site_id' }],
                },
              ],
            },
          ],
        },
      ],
    },
    inverter: {
      manufacturer: [
        {
          label: 'Solar Edge',
          value: 'SolarEdge',
          target_table: 'tgt_fact_inverter_component_reading',
          target_column: 'reading_datetime',
          keyField: 'site_id',
          condition: [{ column_name: 'site_id' }, { column_name: 'reading_datetime' }],
          columns: [
            {
              dataField: 'total_active_power',
              text: 'Total Active Power',
              data_type: 'int',
              editable: false,
              field_type: 'number',
            },
            { dataField: 'dc_voltage', text: 'DC Voltage', data_type: 'int', editable: false, field_type: 'number' },
            {
              dataField: 'total_energy',
              text: 'Total Energy',
              data_type: 'int',
              editable: false,
              field_type: 'number',
            },
            { dataField: 'temperature', text: 'Temperature', data_type: 'int', editable: false, field_type: 'number' },
            {
              dataField: 'inverter_mode',
              text: 'Inverter Mode',
              data_type: 'string',
              editable: true,
              field_type: 'input',
            },
            { dataField: 'ac_current', text: 'AC Current', data_type: 'int', editable: false, field_type: 'number' },
            {
              dataField: 'ac_frequency',
              text: 'AC Frequency',
              data_type: 'int',
              editable: false,
              field_type: 'number',
            },
            { dataField: 'ac_voltage', text: 'AC Voltage', data_type: 'int', editable: false, field_type: 'number' },
            {
              dataField: 'active_power',
              text: 'Active Power',
              data_type: 'int',
              editable: false,
              field_type: 'number',
            },
            {
              dataField: 'apparent_power',
              text: 'Apparent Power',
              data_type: 'int',
              editable: false,
              field_type: 'number',
            },
            {
              dataField: 'reactive_power',
              text: 'Reactive Power',
              data_type: 'int',
              editable: false,
              field_type: 'number',
            },
            {
              dataField: 'ground_fault_resistance',
              text: 'Ground Fault Resistance',
              data_type: 'int',
              editable: false,
              field_type: 'number',
            },
            { dataField: 'cosphi', text: 'Cosphi', data_type: 'int', editable: false, field_type: 'number' },
            {
              dataField: 'reading_datetime',
              text: 'Reading Datetime',
              data_type: 'datetime',
              editable: true,
              field_type: 'input',
            },
            {
              dataField: 'action',
              text: 'Action',
              formatterType: 'action',
              formatterDetails: [
                {
                  name: 'Edit',
                  action: 'functioncall',
                  displayValue: 'site_id',
                  messageNeeded: false,
                  params: [{ key: 'site', value: 'site_id' }],
                },
              ],
            },
          ],
        },
        {
          label: 'Fronius',
          value: 'Fronius',
          target_table: 'tgt_fact_fronius_site_reading',
          target_column: 'device_reading_time',
          condition: [{ column_name: 'site_id' }, { column_name: 'device_reading_time' }],
          keyField: 'site_id',
          columns: [
            { dataField: 'device_id', text: 'Device Id', data_type: 'string', editable: true, field_type: 'input' },
            {
              dataField: 'channeltype',
              text: 'Channel Type',
              data_type: 'string',
              editable: true,
              field_type: 'input',
            },
            { dataField: 'daloid', text: 'Daliod', data_type: 'int', editable: false, field_type: 'number' },
            {
              dataField: 'logdatetime',
              text: 'Log Datetime',
              data_type: 'string',
              editable: true,
              field_type: 'input',
            },
            { dataField: 'nodetype', text: 'Node Type', data_type: 'int', editable: false, field_type: 'input' },
            { dataField: 'devicetype', text: 'Device Type', data_type: 'int', editable: false, field_type: 'input' },
            { dataField: 'value', text: 'Value', data_type: 'int', editable: false, field_type: 'number' },
            { dataField: 'unit', text: 'Unit', data_type: 'int', editable: true, field_type: 'input' },
            {
              dataField: 'device_reading_time',
              text: 'Device Readingtime',
              data_type: 'datetime',
              editable: true,
              field_type: 'number',
            },
            {
              dataField: 'action',
              text: 'Action',
              formatterType: 'action',
              formatterDetails: [
                {
                  name: 'Edit',
                  action: 'functioncall',
                  displayValue: 'site_id',
                  messageNeeded: false,
                  params: [{ key: 'site', value: 'site_id' }],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  season: {
    validation: {
      addSeason: [
        { value: 'name', label: 'Name', type: 'string', required: true },
        { value: 'start_date', label: 'Start Month', type: 'date', required: true },
        { value: 'end_date', label: 'End Month', type: 'date', required: true },
      ],
    },
    tableConfig: {
      keyField: 'id',
      columns: [
        { dataField: 'name', text: 'Season Name', sort: true, data_type: 'string' },
        {
          dataField: 'start_date',
          text: 'Start Month',
          sort: true,
          data_type: 'string',
          formatterType: 'displayText',
          formatterDetails: { fetchfrom: 'fetch_list', value: 'label' },
        },
        {
          dataField: 'end_date',
          text: 'End Month',
          sort: true,
          data_type: 'string',
          formatterType: 'displayText',
          formatterDetails: { fetchfrom: 'fetch_list', value: 'label' },
        },
        {
          dataField: 'action',
          text: 'Action',
          formatterType: 'action',
          formatterDetails: [
            { name: 'Edit', action: '#/season:1', params: [{ key: 'season', value: 'id' }] },
            { name: 'delete_season', action: 'functioncall', displayValue: 'name', messageNeeded: true },
          ],
        },
      ],
    },
  },
};

export default siteConfig;
