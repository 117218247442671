// @ts-nocheck
// file is FUBAR

import { API } from 'aws-amplify';
import React, { Component } from 'react';
import { Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Image as CardImage } from 'react-bootstrap-icons';
import Loader from 'react-loader';
import { toast } from 'react-toastify';
import { getTokenAndEmailFromSession, post } from '../../common/api-utils';
import { displayAPIErrorMessage } from '../../common/utils-helper';
import Sidebar from '../../components/Sidebar';
import Table, { HandleTableChangeProps, SortOrder } from '../../components/table/Table';
import TableSearch from '../../components/table/TableSearch';
import Header from '../../components/TopNav';
import BillsSlidingPane from './dialogs/BillsSlidingPane';
import CircuitsSlidingPane from './dialogs/CircuitsSlidingPane';

// @TODO: remove unused state vars...
const INITIAL_STATE = {
  page: 1,
  data: [],
  sizePerPage: 10,
  currentRow: null,
  loaded: false,
  siteBills: [],
  offset: 0,
  searchTerm: '',
  orderBy: '',
  sortOrder: 'desc' as SortOrder,
  inverterId: null,
  isImageDialogOpen: false,
  siteName: '',
  siteImageUrl: '',
  expandType: 'meter', // Default open tab
  totalSize: 0,
  expandFetchData: [], // Data to be pushed into child tables when a site row is selected
  expandedSiteId: null,
  circuits: [],
  isCircuitsPaneOpen: false,
  monitorTypes: [],
  effectiveDate: null,
  isManageBillSlidingPaneOpen: false,
  subTableData: {
    battery_pack: [],
    inverter: [],
    meter: [],
    solar_module: [],
    ev_charger: [],
    investments: [],
    bills: [],
  },
  selectedBillId: null,
};

interface Props {
  history?: unknown;
}

/**
 * @TODO: refactor fetch methods to use a uniform token/id order for params
 */
export default class Sites extends Component<Props> {
  state = INITIAL_STATE;

  setAsyncState = (newState) => this.setState(newState);

  /* setAsyncState = (newState) => new Promise((resolve) => this.setState(newState, resolve)); */

  async componentDidMount() {
    try {
      const { jwtToken } = await getTokenAndEmailFromSession();
      const { data, item_count: totalSize } = await this.fetchSites(jwtToken, this.state.offset);

      this.setState({
        ...this.state,
        data,
        totalSize,
        loaded: true,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  }

  async fetchSites(token?: string, offset?: number, orderBy?: string, sortOrder?: string, searchValue?: string) {
    if (sortOrder === 'desc') orderBy = `-${orderBy}`;
    if (orderBy?.includes('.')) orderBy = orderBy.replace('.', '__');

    return API.get(
      'sites',
      '/site/sites' +
        `?limit=${this.state.sizePerPage}` +
        `&offset=${offset}` +
        `&ordering=${orderBy || 'clipsal_solar_id'}` +
        (searchValue ?? this.state.searchTerm ? `&search_term=${searchValue ?? this.state.searchTerm}` : ``),
      { headers: { 'Content-Type': 'application/json', Authorization: token } }
    );
  }

  async fetchSiteBills(token, id) {
    const { data: bills } = await API.get('bills', '/site/sites/' + id + '/bills', {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
    return bills;
  }

  async fetchCircuits(token, id) {
    return API.get('circuits', '/site/sites/' + id + '/circuits', {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  }

  async postCircuits(token, siteId, body) {
    return API.post('circuits', '/site/sites/' + siteId + '/circuits', {
      body,
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  }

  async fetchMonitors(token) {
    return API.get('site', '/site/assignments', {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  }

  async deleteSite(siteId, token) {
    return API.del('sites', `/site/sites/${siteId}`, {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  }

  async deleteBill(siteId, token, bill) {
    return API.del('sites', `/site/bills/${bill.id}`, {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  }

  async getSiteImage(siteId) {
    const { jwtToken } = await getTokenAndEmailFromSession();
    return API.get('sites', `/site/sites/${siteId}/image?size=desktop`, {
      headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
    });
  }

  /** Event handlers */
  handleDeleteSite = async (site, token) => {
    const { totalSize } = this.state;
    await this.deleteSite(site.clipsal_solar_id, token);

    toast.success(`Site ${site.site_name} successfully deleted`, {
      autoClose: 5000,
    });

    this.setState({
      ...this.state,
      data: this.state.data.filter((item) => site.clipsal_solar_id !== item.clipsal_solar_id),
      totalSize: totalSize - 1,
    });
  };

  handleDeleteBill = async (siteId, token, bill) => {
    await this.deleteBill(siteId, token, bill);
    // Update state with all bills except the one that was deleted
    this.setState({
      ...this.state,
      expandFetchData: this.state.expandFetchData.filter((billInState) => billInState.id !== bill.id),
    });
  };

  handleInlineEditCircuit = async (newCircuitData) => {
    const { jwtToken } = await getTokenAndEmailFromSession();

    try {
      // Might be worth a refactor to PATCH over POST since only a single record changes
      await this.postCircuits(jwtToken, this.state.expandedSiteId, newCircuitData);

      this.setState({
        ...this.state,
        circuits: newCircuitData,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  handleTableChange = async ({ page, sizePerPage, sortField, sortOrder }: HandleTableChangeProps) => {
    try {
      await this.setAsyncState({
        ...this.state,
        loaded: false,
      });

      const { jwtToken } = await getTokenAndEmailFromSession();
      const offset = (page - 1) * sizePerPage;
      const { data } = await this.fetchSites(jwtToken, offset, sortField, sortOrder);

      this.setState({
        ...this.state,
        page,
        offset,
        orderBy: sortField,
        sortOrder,
        data,
        sizePerPage,
        loaded: true,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  handleListMonitors = async (token, id) => {
    const allMonitorTypes = await this.fetchMonitors(token);

    this.setState({
      circuits: await this.fetchCircuits(token, id),
      loaded: true,
      isCircuitsPaneOpen: true,
      monitorTypes: allMonitorTypes.map((monitorType) => ({
        label: monitorType.assignment,
        value: monitorType.assignment,
      })),
    });
  };

  handleOpenSiteImageModal = async (siteId, siteName) => {
    this.setState({ loaded: false });
    const imageData = await this.getSiteImage(siteId);
    if (imageData && imageData.base64_file && imageData.file_type) {
      this.setState({
        loaded: true,
        isImageDialogOpen: true,
        siteName: siteName,
        siteImageUrl: `data:${imageData.file_type};base64, ${imageData.base64_file}`,
      });
    } else {
      this.setState({ loaded: true, isImageDialogOpen: true, siteName: siteName, siteImageUrl: `` });
    }
  };

  handleHideSiteImageModal = () => {
    this.setState({ isImageDialogOpen: false });
  };

  showSiteImageFormatter = (cell, row) => {
    return (
      <span>
        {cell} &nbsp;
        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip-2">View site image</Tooltip>}>
          {({ ref, ...triggerHandler }) => (
            <span {...triggerHandler}>
              {' '}
              <CardImage
                ref={ref}
                width="18"
                height="18"
                style={{ float: 'right', cursor: 'pointer' }}
                onClick={() => this.handleOpenSiteImageModal(cell, row.site_name)}
              />
            </span>
          )}
        </OverlayTrigger>
      </span>
    );
  };

  handleViewBill = async (token, bill) => {
    const billId = bill.id;

    this.setState({
      ...this.state,
      isManageBillSlidingPaneOpen: true,
      selectedBillId: billId,
    });
  };

  handleViewBillClose = () => {
    this.setState({ billViewURL: null });
    (window as any).$('#billViewModal').modal('hide');
  };

  handleOpenBillSlidingPane = async (token) => {
    const bills = await this.fetchSiteBills(token, this.state.expandedSiteId);
    this.setState({
      ...this.state,
      isManageBillSlidingPaneOpen: true,
      subTableData: {
        ...this.state.subTableData,
        bills,
      },
    });
  };

  handleRefreshCircuits = async (token, site) => {
    await post('refresh_circuits', `/site/sites/${site.clipsal_solar_id}/refresh_circuits`, {}, token);
    toast.success('Successfully refreshed site circuits.');
  };

  handleRefreshImage = async (token, site) => {
    await post('refresh_images', `/site/sites/${site.clipsal_solar_id}/refresh_images`, {}, token);
    toast.success('Successfully refreshed site image.');
  };

  // @TODO: update `type` param to a string union or enum with TypeScript addition
  handleTableAction = async (currentRow, type) => {
    const id = currentRow.clipsal_solar_id;

    await this.setAsyncState({
      ...this.state,
      expandedSiteId: id || this.state.expandedSiteId,
      currentRow,
      loaded: false,
    });

    const { jwtToken } = await getTokenAndEmailFromSession();

    const actionTypeToFunctionMap = {
      delete_site: {
        fn: this.handleDeleteSite,
        args: [currentRow, jwtToken],
      },
      delete_bill: {
        fn: this.handleDeleteBill,
        args: [id, jwtToken, currentRow],
      },
      list_circuits: {
        fn: this.handleListMonitors,
        args: [jwtToken, id, currentRow],
      },
      bill_view: {
        fn: this.handleViewBill,
        args: [jwtToken, currentRow],
      },
      add_bill: {
        fn: this.handleOpenBillSlidingPane,
        args: [jwtToken],
      },
      refresh_circuits: {
        fn: this.handleRefreshCircuits,
        args: [jwtToken, currentRow],
      },
      view_in_pulse: {
        fn: (token, site) => {
          window.open(`https://app.clipsalcortex.com/site/${site.clipsal_solar_id}/dashboard`);
        },
        args: [jwtToken, currentRow],
      },
      refresh_image: {
        fn: this.handleRefreshImage,
        args: [jwtToken, currentRow],
      },
    };

    if (type in actionTypeToFunctionMap) {
      const { fn, args } = actionTypeToFunctionMap[type];
      try {
        await fn(...args);
      } catch (e) {
        displayAPIErrorMessage(e);
      }

      this.setState({
        ...this.state,
        loaded: true,
      });
    }
  };

  handleCloseSlidingPane = () => {
    this.setState({
      ...this.state,
      selectedBillId: null,
      isCircuitsPaneOpen: false,
      isManageBillSlidingPaneOpen: false,
    });
  };

  handleExpandFetch = async (expandType, row) => {
    const { jwtToken } = await getTokenAndEmailFromSession();
    const subTableTypeToAPISuffix = {
      battery_pack: 'batteries',
      inverter: 'inverters',
      meter: 'meters',
      solar_module: 'solar_modules',
      ev_charger: 'ev_chargers',
      investments: 'investments',
      bills: 'bills',
    };

    if (row.clipsal_solar_id !== this.state.expandedSiteId) {
      await this.setAsyncState({
        ...this.state,
        expandFetchData: [],
        subTableData: INITIAL_STATE.subTableData,
      });
    }

    let data = this.state.subTableData[expandType];
    if (!data.length) {
      const endPoint = subTableTypeToAPISuffix[expandType.toLowerCase()];

      if (expandType === 'bills') {
        const { data: bills } = await API.get('site-device', '/site/sites/' + row.clipsal_solar_id + `/${endPoint}`, {
          headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
        });
        data = bills;
      } else {
        data = await API.get('site-device', '/site/sites/' + row.clipsal_solar_id + `/${endPoint}`, {
          headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
        });
      }

      // Cache api response in state
      this.setState({
        ...this.state,
        subTableData: {
          ...this.state.subTableData,
          [expandType]: data,
        },
      });
    }

    this.setState({
      expandedSiteId: row.clipsal_solar_id,
      expandType,
      expandFetchData: data,
    });
  };

  handleTableSearch = async (searchTerm) => {
    const { jwtToken } = await getTokenAndEmailFromSession();

    const { data, item_count: totalSize } = await this.fetchSites(
      jwtToken,
      0,
      this.state.orderBy,
      this.state.sortOrder,
      searchTerm
    );

    this.setState({
      ...this.state,
      data,
      page: 1,
      totalSize,
      offset: 0,
      searchTerm: searchTerm || null,
    });
  };

  handleChangePageSize = async (e) => {
    await this.setAsyncState({
      ...this.state,
      loaded: false,
      sizePerPage: Number(e.currentTarget.value),
    });

    const { jwtToken } = await getTokenAndEmailFromSession();
    const { data } = await this.fetchSites(jwtToken, this.state.offset);

    this.setState({
      ...this.state,
      data,
      loaded: true,
    });
  };

  render() {
    const { isCircuitsPaneOpen, circuits, effectiveDate, isManageBillSlidingPaneOpen } = this.state;

    return (
      <div>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <div role="main" className="content-panel">
              <div className="content-section">
                <div className="content-head">
                  <div className="border-bottom-gray d-flex">
                    <span className="header-span">Sites</span>
                    <div className="data-brief pull-right text-right">
                      <div className="pull-right">
                        <p>Total Sites</p>
                        <h2>{this.state.totalSize}</h2>
                      </div>
                    </div>
                  </div>
                  <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#/sites">Clipsal</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Sites
                      </li>
                    </ol>
                  </nav>
                </div>
                <div>
                  <TableSearch onSearch={this.handleTableSearch} containerStyle={{ width: '25%', margin: '1rem 0' }} />
                  <Table
                    data={this.state.data}
                    onChangePageSize={this.handleChangePageSize}
                    page={this.state.page}
                    sortOrder={this.state.sortOrder}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    type="site"
                    remote
                    history={this.props.history}
                    onTableChange={this.handleTableChange}
                    onHandleAction={this.handleTableAction}
                    onExpandFetch={this.handleExpandFetch}
                    expandFetchData={this.state.expandFetchData}
                    showSiteImageFormatter={this.showSiteImageFormatter}
                    expandType={this.state.expandType}
                  />
                  <Loader loaded={this.state.loaded} />
                </div>
                {isCircuitsPaneOpen && (
                  <CircuitsSlidingPane
                    siteId={this.state.expandedSiteId}
                    circuitsData={circuits}
                    monitorTypes={this.state.monitorTypes}
                    onRequestClose={this.handleCloseSlidingPane}
                    setCircuitData={this.handleInlineEditCircuit}
                  />
                )}
                {isManageBillSlidingPaneOpen && (
                  <BillsSlidingPane
                    bills={this.state.subTableData.bills}
                    initiallySelectedBillId={this.state.selectedBillId}
                    onUpdateBills={(bills) =>
                      this.setState({ ...this.state, subTableData: { ...this.state.subTableData, bills } })
                    }
                    siteId={this.state.expandedSiteId}
                    onRequestClose={this.handleCloseSlidingPane}
                  />
                )}
                {this.state.isImageDialogOpen && (
                  <Modal show={true} onHide={this.handleHideSiteImageModal} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title id="site-image-modal-styling-title">
                        Site Name: <strong>{this.state.siteName}</strong>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Image fluid src={this.state.siteImageUrl} />
                    </Modal.Body>
                  </Modal>
                )}
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
