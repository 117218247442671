import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { FiRefreshCw } from 'react-icons/fi';
import { toZonedTime } from 'date-fns-tz';
import { getTokenAndEmailFromSession } from '../../../common/api-utils';
import { get } from '../../../common/api-utils';
import { capitalizeFirstChar, displayAPIErrorMessage, formatDate, formatTime } from '../../../common/utils-helper';
import config from '../../../components/chart-colors.config';
import { Flex } from '@chakra-ui/layout';
import { Spinner as ThinSpinner } from '@chakra-ui/react';
import Select, { StylesConfig } from 'react-select';
import { FORMATTED_TIMEZONES_FOR_SELECT } from '../../../utils/timezone';
import { ChevronDownIcon } from '@chakra-ui/icons';
import useInterval from '../../../common/hooks/use-interval';
import { buildLongChartData, buildShortChartData, DataTypeChartSeriesConfig } from './chart-data-transformers';
import LongEnergyTable from './LongEnergyTable';
import LatestDataTable from './LatestDataTable';
import IntervalTypeButtonGroup from './IntervalTypeButtonGroup';
import ChartDataTypeButtonGroup from './ChartDataTypeButtonGroup';
import { ChannelDataTuple } from '../../../api/api-ww-meter';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import moment from 'moment';
import { cloneDeep } from 'lodash';

export type ChartDataType =
  | 'powerReal'
  | 'powerReactive'
  | 'current'
  | 'voltage'
  | 'powerFactor'
  | 'currentMin'
  | 'currentMax'
  | 'voltageMin'
  | 'voltageMax';

export type IntervalType = 'short_energy' | 'long_energy';

type State = {
  isLoaded: boolean;
  isFetchingUsage: boolean;
  isBuildingChart: boolean;
  selectedInterval: IntervalType;
  selectedDataType: ChartDataType;
  powerRealChartData: DataTypeChartSeriesConfig;
  powerReactiveChartData: DataTypeChartSeriesConfig;
  currentChartData: DataTypeChartSeriesConfig;
  voltageChartData: DataTypeChartSeriesConfig;
  voltageMaxChartData: DataTypeChartSeriesConfig;
  voltageMinChartData: DataTypeChartSeriesConfig;
  currentMaxChartData: DataTypeChartSeriesConfig;
  currentMinChartData: DataTypeChartSeriesConfig;
  powerFactorChartData: DataTypeChartSeriesConfig;
  selectedDate: Date;
  hiddenChannelIndexes: number[];
  isChartDataAvailable: boolean;
  timezone: string;
  oldTimezone: string;
};

const INITIAL_STATE: State = {
  isLoaded: false,
  isFetchingUsage: false,
  isBuildingChart: false,
  selectedInterval: 'short_energy',
  selectedDataType: 'powerReal',
  powerRealChartData: {
    name: null,
    data: [],
  },
  powerReactiveChartData: {
    name: null,
    data: [],
  },
  currentChartData: {
    name: null,
    data: [],
  },
  voltageChartData: {
    name: null,
    data: [],
  },
  voltageMaxChartData: {
    name: null,
    data: [],
  },
  voltageMinChartData: {
    name: null,
    data: [],
  },
  currentMaxChartData: {
    name: null,
    data: [],
  },
  currentMinChartData: {
    name: null,
    data: [],
  },
  powerFactorChartData: {
    name: null,
    data: [],
  },
  selectedDate: new Date(),
  hiddenChannelIndexes: [],
  isChartDataAvailable: false,
  timezone: '',
  oldTimezone: '',
};

const DATA_TYPE_TO_VALUE_SUFFIX = {
  powerReal: 'kW',
  powerReactive: 'kW',
  powerFactor: '',
  current: 'Amps',
  currentMin: 'Amps',
  currentMax: 'Amps',
  voltage: 'Volts',
  voltageMin: 'Volts',
  voltageMax: 'Volts',
};

const INTERVAL_TIMER_MS = 5000;

const CUSTOM_STYLES: StylesConfig<
  {
    label: string;
    value: string;
  },
  false
> = {
  control: (provided, state) => ({
    ...provided,
    minWidth: '240px',
    padding: 2,
    borderRadius: 6,
    boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : 'none',
    borderColor: state.isFocused ? '#3182ce' : 'gray.200',
    '&:hover': {
      borderColor: state.isFocused ? '#3182ce' : 'gray.200',
    },
  }),
  menu: (provided) => ({ ...provided, minWidth: '240px' }),
};

const ROUND_BUTTON_CONFIG = {
  margin: '0 0.8rem',
  borderRadius: '50px',
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const COMMON_CHART_DATETIME_FORMATS = {
  second: '%l:%M:%S %P',
  minute: '%l:%M %P',
  hour: '%l %P',
  day: '%e. %b',
  week: '%e. %b',
  month: "%b '%y",
  year: '%Y',
};

type Props = {
  serialNumber: string;
  channels: ChannelDataTuple;
  timezone: string;
};

export default function MeterConfigurationChartContainer({ serialNumber, channels, timezone }: Props) {
  const [state, setState] = useState<State>(INITIAL_STATE);
  const chartRef = useRef(null);
  // I wasted far too long debugging strange UI issues to figure out the React Highcharts wrapper breaks basic React
  // core principles by mutating the chart props object passed to it directly (apparently for "performance reasons").
  // Because of this, we have to deep clone the state object being passed to the chart, otherwise countless weird bugs
  // end up happening.
  // see: https://github.com/highcharts/highcharts-react#why-highcharts-mutates-my-data
  const visibleChartData = cloneDeep(state[`${state.selectedDataType}ChartData`]);

  const checkDateBeforeToday = (date) => date <= toZonedTime(new Date(), state.timezone);

  useFetchInterval(serialNumber, state, setState, channels);

  useEffect(() => {
    if (state.selectedInterval === 'long_energy' && state.isLoaded) {
      setState((prevState) => ({ ...prevState, isBuildingChart: true }));
      fetchLongEnergy();
    }
  }, [state.selectedDate, serialNumber]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, timezone }));
  }, [timezone]);

  async function fetchLongEnergy() {
    try {
      const { jwtToken: token } = await getTokenAndEmailFromSession();
      // Get the initial data
      const data = await get(
        'long_energy',
        `/device/wattwatchers/devices/${serialNumber}/long_energy?reading_date=${formatDate(
          state.selectedDate
        )}&timezone=${state.timezone}`,
        token
      );

      const chartData = buildLongChartData(data, channels);

      setState({
        ...state,
        ...chartData,
        isFetchingUsage: false,
        isBuildingChart: false,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  }

  function getLastCommunicatedTime() {
    const latestTime = visibleChartData.data[0].data[visibleChartData.data[0].data.length - 1]?.x;
    if (latestTime) return formatTime(toZonedTime(new Date(latestTime), state.timezone));
  }

  function getChartOptions() {
    return {
      title: {
        text: state.selectedInterval
          .split('_')
          .map((word) => capitalizeFirstChar(word))
          .join(' '),
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
      },
      yAxis: {
        title: {
          text: visibleChartData.name,
        },
        labels: {
          formatter: function () {
            return this.value + ` ${DATA_TYPE_TO_VALUE_SUFFIX[state.selectedDataType]}`;
          },
        },
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
      },
      time: {
        moment: moment,
        timezone: state.timezone,
      },
      tooltip: {
        animation: false,
        shared: true,
        dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
      },
      series: visibleChartData.data.map((series, index) => {
        return {
          ...series,
          visible: !state.hiddenChannelIndexes.includes(index),
        };
      }),
      colors: config,
      chart: {
        zoomType: 'x',
        height: '400px',
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
          events: {
            legendItemClick: function (e) {
              e.preventDefault();
              toggleLineState(this.index);
            },
          },
        },
      },
    };
  }

  function handleToggleLine(channelIndex: number) {
    chartRef.current.chart.series[channelIndex].setVisible(!chartRef.current.chart.series[channelIndex].visible);
    toggleLineState(channelIndex);
  }

  function toggleLineState(channelIndex: number) {
    const hiddenKeys = state.hiddenChannelIndexes;

    if (hiddenKeys.includes(channelIndex)) {
      setState({
        ...state,
        hiddenChannelIndexes: state.hiddenChannelIndexes.filter((key) => key !== channelIndex),
      });
    } else {
      setState({
        ...state,
        hiddenChannelIndexes: [...state.hiddenChannelIndexes, channelIndex],
      });
    }
  }

  return (
    <div style={{ margin: '2rem 0' }}>
      <Card>
        <Card.Header>
          <h3>Visualise</h3>
        </Card.Header>

        <Card.Body>
          <div>
            <IntervalTypeButtonGroup
              onSetIntervalType={(type) =>
                setState({
                  ...state,
                  isLoaded: false,
                  selectedInterval: type,
                  selectedDataType: 'powerReal',
                })
              }
              selectedInterval={state.selectedInterval}
            />

            <ChartDataTypeButtonGroup
              selectedDataType={state.selectedDataType}
              selectedInterval={state.selectedInterval}
              onSetDataType={(type) =>
                setState({
                  ...state,
                  selectedDataType: type,
                })
              }
            />
          </div>

          <Flex justify="space-between" align="center" mt={3}>
            <Flex direction="column">
              <label>Set Timezone</label>
              <Flex align="center">
                <Select
                  data-testid="meter-timezone-temp"
                  styles={CUSTOM_STYLES}
                  isSearchable
                  placeholder={'Select a timezone...'}
                  options={FORMATTED_TIMEZONES_FOR_SELECT}
                  value={{ value: state.timezone, label: state.timezone }}
                  onChange={(newTimezone) => setState((prevState) => ({ ...prevState, timezone: newTimezone.value }))}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => <ChevronDownIcon w={8} h={8} mr={2} />,
                  }}
                />
                {state.isBuildingChart && <ThinSpinner size="lg" ml={4} />}
              </Flex>
            </Flex>
            {state.selectedInterval === 'short_energy' && state.isChartDataAvailable && (
              <Flex mt="auto">
                <p>Last communicated at {getLastCommunicatedTime()}</p>
                <h3 style={{ color: 'red', marginLeft: '1rem' }}>
                  <Spinner variant="danger" animation="grow" /> Live
                </h3>
              </Flex>
            )}

            {state.selectedInterval === 'long_energy' && (
              <Flex direction="column">
                <label className="label-zindex" style={{ marginLeft: '50px' }}>
                  Set Effective Date
                </label>
                <Flex align="center">
                  <Button
                    onClick={() => {
                      if (state.isFetchingUsage) {
                        return;
                      }

                      const date = new Date(state.selectedDate.getTime());
                      date.setDate(date.getDate() - 1);

                      setState({
                        ...state,
                        selectedDate: date,
                        isFetchingUsage: true,
                      });
                    }}
                    disabled={!state.isLoaded}
                    style={ROUND_BUTTON_CONFIG}
                    variant="outline-secondary"
                    data-testid="date-picker-back-btn"
                  >
                    <AiOutlineLeft size={19} />
                  </Button>
                  <DatePicker
                    data-testid="date-picker-input"
                    filterDate={checkDateBeforeToday}
                    className={'custom_datepicker'}
                    id="selectedDate"
                    placeholderText="Select a date..."
                    dateFormat="dd-MM-yyyy"
                    autoComplete="off"
                    selected={state.selectedDate}
                    onChange={(e) => {
                      if (state.isFetchingUsage) {
                        return;
                      }

                      setState({
                        ...state,
                        isFetchingUsage: true,
                        selectedDate: new Date((e as Date).getTime()),
                      });
                    }}
                  />
                  <Button
                    onClick={() => {
                      const date = new Date(state.selectedDate.getTime());

                      // Can't go into the future
                      if (state.isFetchingUsage || !checkDateBeforeToday(date)) {
                        return;
                      }

                      date.setDate(date.getDate() + 1);

                      setState({
                        ...state,
                        isFetchingUsage: true,
                        selectedDate: date,
                      });
                    }}
                    disabled={
                      !state.isLoaded ||
                      formatDate(state.selectedDate) === formatDate(toZonedTime(new Date(), state.timezone))
                    }
                    style={ROUND_BUTTON_CONFIG}
                    variant="outline-secondary"
                    data-testid="date-picker-forward-btn"
                  >
                    <AiOutlineRight size={19} />
                  </Button>

                  <Button style={ROUND_BUTTON_CONFIG} onClick={fetchLongEnergy} variant="success">
                    <FiRefreshCw size={19} />
                  </Button>
                </Flex>
              </Flex>
            )}
          </Flex>

          {state.isLoaded ? (
            <div style={{ margin: '1rem 0' }} data-testid="line-chart">
              {state.isLoaded && !visibleChartData.data.some(({ data }) => data.length) ? (
                <Alert style={{ margin: '1rem 0' }} variant="danger">
                  <Alert.Heading>
                    <h3>There is no data for this device on this day.</h3>
                  </Alert.Heading>
                </Alert>
              ) : (
                <Chart ref={chartRef} highcharts={Highcharts} options={getChartOptions()} />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                {state.selectedInterval === 'short_energy' ? (
                  <LatestDataTable
                    latestPolledTimestamp={getLastCommunicatedTime()}
                    channels={channels}
                    onToggleLine={handleToggleLine}
                    hiddenIndexes={state.hiddenChannelIndexes}
                    voltageData={state.voltageChartData}
                    currentData={state.currentChartData}
                    powerReactiveData={state.powerReactiveChartData}
                    powerRealData={state.powerRealChartData}
                    powerFactorData={state.powerFactorChartData}
                    isChartDataAvailable={state.isChartDataAvailable}
                  />
                ) : (
                  <LongEnergyTable
                    channels={channels}
                    onToggleLine={handleToggleLine}
                    hiddenIndexes={state.hiddenChannelIndexes}
                  />
                )}
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
              <Spinner style={{ width: '50px', height: '50px' }} animation="border" variant="primary" />
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

function useFetchInterval(serialNumber, state, setState, channels) {
  useInterval(async () => {
    // Only poll for short energy
    if (state.selectedInterval === 'short_energy' && state.currentChartData.data.length) {
      try {
        const { jwtToken: token } = await getTokenAndEmailFromSession();
        const data = await get(
          'long_energy',
          `/device/wattwatchers/devices/${serialNumber}/${state.selectedInterval}`,
          token
        );

        const chartData = buildShortChartData(data, channels);

        setState({
          ...state,
          ...chartData,
          isChartDataAvailable: Object.keys(data).length > 0,
        });
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }
  }, INTERVAL_TIMER_MS);

  // Initial fetch, calls initially for both long AND short energy
  useEffect(() => {
    async function fetchAPI() {
      try {
        setState((prevState) => ({ ...prevState, isBuildingChart: true }));
        const { jwtToken: token } = await getTokenAndEmailFromSession();
        let uri = `/device/wattwatchers/devices/${serialNumber}/${state.selectedInterval}`;
        const timezone = state.timezone;

        if (state.selectedInterval === 'long_energy') {
          uri += `?reading_date=${formatDate(state.selectedDate)}&timezone=${timezone}`;
        }

        const data = await get(state.selectedInterval, uri, token);

        const intervalTypeToFormatterMap = {
          long_energy: buildLongChartData,
          short_energy: buildShortChartData,
        };

        const formatter = intervalTypeToFormatterMap[state.selectedInterval];

        const chartData = formatter(data, channels, state.timezone);

        setState((prevState) => ({
          ...prevState,
          ...chartData,
          isLoaded: true,
          isBuildingChart: false,
          isChartDataAvailable: Object.keys(data).length > 0,
        }));
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }

    if (!!state.timezone && state.timezone !== state.oldTimezone) {
      const currentDateTime = toZonedTime(new Date(), state.timezone);
      setState((prevState) => ({ ...prevState, selectedDate: currentDateTime, oldTimezone: state.timezone }));
      if (state.selectedInterval === 'short_energy') fetchAPI();
    } else if (!state.isLoaded) {
      fetchAPI();
    }
  }, [state.selectedInterval, state.selectedDate, serialNumber, state.timezone]);
}
