// @ts-nocheck
// NOTE: file is FUBAR

import { faMinusCircle, faPlusCircle, faSave, faTable, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { getTokenAndEmailFromSession } from '../../common/api-utils';
import config from '../../common/site-config';
import { displayAPIErrorMessage, formValidation } from '../../common/utils-helper';
import InvestmentDescription from './InvestmentDescription';

const INITIAL_STATE = {
  investments: [],
  originalInvestments: [],
  deletedInvestments: [],
  products: [],
  currentlyEditingDescriptionInvestment: null,
};

const INITIAL_INVESTMENT = {
  investment_type: 'SINGLE',
  description: '',
  installation_date: '',
  no_of_payments: 1,
  billing_period: 'SINGLE',
  amount: '',
  readOnly: false,
  isNewInvestment: true,
};

const INVETSMENT_VALIDATION = [
  { value: 'investment_type', label: 'Investment Type', type: 'string', required: true },
  { value: 'description', label: 'Description', type: 'string', required: false },
  { value: 'installation_date', label: 'Installation Date', type: 'date', required: true },
  { value: 'no_of_payments', label: 'No of Payments', type: 'string', required: true },
  { value: 'billing_period', label: 'Billing Period', type: 'string', required: true },
  { value: 'amount', label: 'Amount', type: 'string', required: true },
];

/**
 * Houses form functionality for investment site information to be used within the `Site` component.
 *
 * // @TODO: refactor this entire component, it's overcomplicated, confusing and conflated
 * @returns {JSX.Element}
 *
 * @constructor
 * @component
 */
export default function SiteInvestmentInformation({ siteId, handleCancel, isParentLoaded }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    async function initialFetch() {
      if (isParentLoaded && siteId) {
        let newState = { ...state };
        const { jwtToken } = await getTokenAndEmailFromSession();
        newState = {
          ...newState,
          siteId,
          products: await fetchSiteProducts(siteId, jwtToken),
          investments: await fetchSiteInvestments(jwtToken),
        };

        //Clone the original values
        newState.originalInvestments = newState.investments.map((i) => ({ ...i }));
        if (!newState.investments.length) {
          newState.investments = newState.investments.concat(INITIAL_INVESTMENT);
        }
        setState({ ...newState, isLoaded: true });
      }
    }
    if (isParentLoaded) {
      initialFetch();
    }
  }, [siteId, isParentLoaded]);

  const fetchSiteProducts = async (siteId, token) => {
    return API.get('sites', '/site/products', {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  };

  const fetchSiteInvestments = async (token) => {
    return API.get('investments', '/site/sites/' + siteId + '/investments', {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  };

  const handleAddInvestment = (event) => {
    event.preventDefault();
    const updatedInvestments = state.investments.map((investment) => ({ ...investment }));
    for (let i = 0; i < updatedInvestments.length; i++) {
      const validationMsg = formValidation(updatedInvestments[i], INVETSMENT_VALIDATION);
      if (validationMsg) {
        toast.error(validationMsg, {
          autoClose: 5000,
        });
        return false;
      }
    }
    setState({
      ...state,
      investments: updatedInvestments.concat({ ...INITIAL_INVESTMENT, newInvestmentId: Math.random() * 10000 }),
    });
  };

  const handleUpdateInvestment = (event, type, index) => {
    const { investments } = state;
    const updatedInvestments = investments.map((investment) => ({ ...investment }));
    const itemKey = type;
    let itemVal = null;
    if (event.target) {
      itemVal = event.target.value;
    } else if (type === 'installation_date') {
      itemVal = event;
    } else {
      itemVal = event.value;
    }

    if (updatedInvestments[index]) {
      updatedInvestments[index][itemKey] = itemVal;
    }
    setState({ ...state, investments: updatedInvestments });
  };

  const handleRemoveInvestment = (event, index) => {
    event.preventDefault();
    const deletedInvestments = state.deletedInvestments.map((investment) => ({ ...investment }));
    const deleted = state.investments[index];
    if (deleted && deleted.id) {
      deletedInvestments.push(deleted);
    }
    const updatedInvestments = state.investments.filter((investment, arrayIndex) => index !== arrayIndex);
    if (updatedInvestments.length === 0) {
      updatedInvestments.push(INITIAL_INVESTMENT);
    }
    setState({ ...state, investments: updatedInvestments, deletedInvestments });
  };

  const handleSaveInvestmentAssociation = async (event) => {
    event.preventDefault();
    setState({ ...state, loaded: false });
    const { investments, deletedInvestments } = state;

    // @FIXME: Disgusting temporary hotfix, this whole component badly needs a full refactor
    const investmentsToSave = investments.map((inv) => ({
      ...inv,
      no_of_payments: inv.no_of_payments ?? 1,
      billing_period: inv.billing_period ?? 'SINGLE',
    }));

    try {
      let formHasError = false;

      investmentsToSave.forEach((investment, index) => {
        const rowError = formValidation(investment, INVETSMENT_VALIDATION);
        if (rowError) {
          toast.error(rowError + ' in row ' + (index + 1), {
            autoClose: 5000,
          });
          formHasError = true;
        }
      });

      if (formHasError) {
        setState({ ...state, loaded: true });
        return false;
      }

      const { jwtToken } = await getTokenAndEmailFromSession();
      const newDeletedInvestments = deletedInvestments.filter(
        (deletedInvestment) =>
          !deletedInvestment.id || !investmentsToSave.find((investment) => investment.id === deletedInvestment.id)
      );
      const formattedInvestments = [];
      if (investmentsToSave.length) {
        investmentsToSave.forEach((investment) => {
          if (investment.installation_date) {
            formattedInvestments.push({
              id: investment.id,
              clipsal_solar_id: siteId,
              investment_type: investment.investment_type,
              products: investment.products,
              installation_date: moment(investment.installation_date).format('YYYY-MM-DD'),
              no_of_payments: investment.no_of_payments ?? 1, // Default to 1 if null
              billing_period: investment.billing_period,
              amount: investment.amount,
            });
          }
        });

        const existingInvestments = formattedInvestments.filter((investment) => investment.id);
        const newInvestments = formattedInvestments.filter((investment) => !investment.id);

        const updatedInvestments = await Promise.all(
          existingInvestments.map((investment) => {
            return API.patch('investments', `/site/sites/${siteId}/investments/${investment.id}`, {
              body: investment,
              headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
            });
          })
        );

        const newlyCreatedInvestments = await API.post('investments', `/site/sites/${siteId}/investments`, {
          body: newInvestments,
          headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
        });

        if (newDeletedInvestments.length) {
          const deletedInvestmentIds = newDeletedInvestments.map((deletedInvestment) => deletedInvestment.id);
          for (const deletedInvestmentId of deletedInvestmentIds) {
            await API.del('investments', `/site/sites/${siteId}/investments/${deletedInvestmentId.toString()}`, {
              headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
            });
          }

          toast.success('Investments updated  successfully', {
            autoClose: 5000,
          });
        } else {
          toast.success('Investments saved  successfully', {
            autoClose: 5000,
          });
        }
        setState({
          ...state,
          deletedInvestments: [],
          originalInvestments: [...updatedInvestments, ...newlyCreatedInvestments],
          investments: [...updatedInvestments, ...newlyCreatedInvestments],
        });
      }
    } catch (e) {
      displayAPIErrorMessage(e);
      setState({ ...state, loaded: false });
    }
  };

  const showInvestmentDescriptionModal = (index) => {
    setState({ ...state, currentlyEditingDescriptionInvestment: investments[index] });
  };

  const handleCloseDescriptionModal = () => {
    setState({ ...state, currentlyEditingDescriptionInvestment: null });
  };

  const handleSaveDescription = (productDescriptionFormData) => {
    // Convert form data to the right structure
    const productsForInvestment = productDescriptionFormData.products.map((formRowData) => {
      const selectedProduct = state.products.find((p) => Number(p.id) === Number(formRowData.description));

      if (formRowData.id) {
        return {
          id: formRowData.id,
          investment_id: state.currentlyEditingDescriptionInvestment.id,
          product_id: Number(formRowData.description),
          size: formRowData.size.toString() ?? null,
          product_metadata: {
            id: Number(formRowData.description),
            category: selectedProduct.category,
            description: selectedProduct.description,
            unit: selectedProduct.units,
          },
        };
      } else {
        return {
          product_id: Number(formRowData.description),
          size: formRowData.size.toString() ?? null,
        };
      }
    });

    const savedInvestment = {
      ...state.currentlyEditingDescriptionInvestment,
      products: productsForInvestment,
    };

    const newInvestments = savedInvestment.isNewInvestment
      ? [
          ...investments.filter((investment) => investment.newInvestmentId !== savedInvestment.newInvestmentId),
          savedInvestment,
        ]
      : [...investments.filter((investment) => investment.id !== savedInvestment.id), savedInvestment];
    setState({ ...state, investments: newInvestments, currentlyEditingDescriptionInvestment: null });
  };

  const { investments, products } = state;

  return (
    <div>
      <div className="tabular-data">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Investment Type </th>
              <th scope="col">Description</th>
              <th scope="col">Install Date</th>
              <th scope="col">No of Payments</th>
              <th scope="col">Billing Period</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            {investments.map((item, index) => {
              // @TODO: refactor this awful code...
              const tdstyle = { width: '275px' };
              if (item.installation_date && item.installation_date !== '') {
                if (item.installation_date !== '0000-00-00') {
                  item.installation_date = new Date(item.installation_date);
                } else {
                  item.installation_date = null;
                }
              }
              if (item.amount === 0) {
                item.amount = '0';
              }
              const selectStyles = { menu: (styles) => ({ ...styles, zIndex: 999 }) };
              //@TODO: Refactor the Table
              return (
                <tr key={index}>
                  {config.site.investment.map((uiItem, uiIndex) => {
                    let validations = null;
                    let labelval = item[uiItem.value];
                    const display = uiItem.display;
                    const list = uiItem.list;
                    let disabled = item.readOnly;
                    if (uiItem.validation) {
                      if (labelval === undefined || labelval === null) {
                        labelval = item[uiItem.validation.dependsOn];
                      }
                    }
                    if (list) {
                      labelval = list.find((listitem) => listitem.value === labelval);
                      if (labelval === undefined || labelval === null) {
                        if (uiItem.unmatched) {
                          labelval = uiItem.unmatched;
                        }
                      }
                    }
                    if (uiItem.validation) {
                      validations = uiItem.validation;
                      if (item[validations.dependsOn] === validations.val.value) {
                        labelval = validations.defaultvalue;
                        disabled = validations.disable;
                      }
                    }
                    if (uiItem.type === 'select') {
                      return (
                        <td style={tdstyle} key={uiIndex}>
                          <Select
                            isDisabled={disabled}
                            styles={selectStyles}
                            options={uiItem.list}
                            value={labelval}
                            isSearchable
                            onChange={(event) => handleUpdateInvestment(event, uiItem.value, index)}
                          />
                        </td>
                      );
                    } else if (uiItem.type === 'date') {
                      return (
                        <td scope="row" key={uiIndex}>
                          <DatePicker
                            className="form-control"
                            id={uiItem.value}
                            placeholderText={uiItem.placeholder}
                            selected={labelval}
                            name={uiItem.value}
                            dateFormat="yyyy-MM-dd"
                            onChange={(event) => handleUpdateInvestment(event, uiItem.value, index)}
                          />
                        </td>
                      );
                    } else if (uiItem.type === 'popup') {
                      return (
                        <td scope="row" key={uiIndex}>
                          <div className="td-icon" onClick={() => showInvestmentDescriptionModal(index)}>
                            <FontAwesomeIcon icon={faTable} className="icon-disp btn-img-pos" />
                          </div>
                        </td>
                      );
                    } else {
                      if (display === undefined || display === true) {
                        return (
                          <td scope="row" key={uiIndex}>
                            <input
                              type="text"
                              disabled={disabled}
                              className="form-control"
                              id={uiItem.value}
                              placeholder={uiItem.placeholder}
                              value={labelval}
                              onChange={(event) => handleUpdateInvestment(event, uiItem.value, index)}
                            />
                          </td>
                        );
                      }
                    }
                  })}

                  <td className="d-flex">
                    {investments.length > 1 && (
                      <React.Fragment>
                        <button
                          className="btn btn-upload btn-single-icon"
                          onClick={(event) => handleRemoveInvestment(event, index)}
                        >
                          <FontAwesomeIcon icon={faMinusCircle} />
                        </button>
                        <span>&nbsp;&nbsp;</span>
                      </React.Fragment>
                    )}
                    {index === investments.length - 1 && (
                      <React.Fragment>
                        <button onClick={handleAddInvestment} className="btn btn-upload btn-single-icon">
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </button>
                      </React.Fragment>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="btn-wrapper text-right mt-3">
        <button className="btn btn-upload" onClick={handleCancel}>
          <FontAwesomeIcon className="btn-img-pos" icon={faTimes} />
          Cancel
        </button>
        <button className="btn btn-upload ml-3" onClick={handleSaveInvestmentAssociation}>
          <FontAwesomeIcon className="btn-img-pos" icon={faSave} />
          Save Investments
        </button>
      </div>

      {!!state.currentlyEditingDescriptionInvestment && (
        <InvestmentDescription
          isOpen
          investment={state.currentlyEditingDescriptionInvestment}
          products={products}
          onSaveInvestmentDescription={handleSaveDescription}
          onClose={handleCloseDescriptionModal}
        />
      )}
    </div>
  );
}
