import { Button } from '@chakra-ui/button';
import { API, Auth } from 'aws-amplify';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { displayAPIErrorMessage } from '../../common/utils-helper';

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getContainerStyle = () => ({ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '5px' });

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 ${grid}px`,
  background: '#3DCD58',
  width: '20%',
  ...draggableStyle,
});

const getCircuitListStyle = (isDraggingOver, listHasItems) => ({
  ...getListStyle(isDraggingOver, listHasItems),
  marginBottom: '3rem',
});

const getListStyle = (isDraggingOver, listHasItems) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: '100%',
  minHeight: '60px',
  display: 'flex',
  overflow: 'auto',
  outline: listHasItems ? undefined : '2px dotted grey',
  outlineOffset: '-5px',
  justifyContent: listHasItems ? 'inherit' : 'center',
  alignItems: listHasItems ? 'inherit' : 'center',
});

interface ICircuitApplianceAssociationDragDropProps {
  associationState?: {
    currentlyEditingApplianceName?: string[] | string;
    appliances?: any;
    circuits?: any;
    editingIndex?: number;
    isAddApplianceBtnHovered?: any;
  };
  siteId?: any;
  setAssociationState?: (val) => void;
}

export default class CircuitApplianceAssociationDragDrop extends Component<ICircuitApplianceAssociationDragDropProps> {
  id2List: unknown;
  async componentDidMount() {
    document.body.style.overflow = 'hidden';

    this.id2List = Object.fromEntries(
      Object.entries(this.props.associationState).map(([key]) => {
        return [key, key];
      })
    );
  }

  componentWillUnmount() {
    // Allow body overflow so users can scroll behind the dialog
    document.body.style.overflow = 'auto';
  }

  getList = (id) => this.props.associationState[this.id2List[id]];

  getCircuitsAtIndex = (index) => this.props.associationState[`droppable-${index}`];

  handleAddAppliance = () => {
    const applianceNumber = this.props.associationState.appliances.length;
    const newAppliance = {
      appliance_name: 'Appliance' + applianceNumber,
      circuits: [],
    };

    try {
      this.id2List[`droppable-${applianceNumber}`] = `droppable-${applianceNumber}`;

      this.props.setAssociationState({
        ...this.props.associationState,
        [`droppable-${applianceNumber}`]: [],
        appliances: [...this.props.associationState.appliances, newAppliance],
      });

      toast.success('Successfully added appliance.');
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  handleDeleteAppliance = async (index) => {
    const circuitsOnAppliance = this.getCircuitsAtIndex(index);
    const assocStateClone = { ...this.props.associationState };

    assocStateClone.circuits = [...assocStateClone.circuits, ...circuitsOnAppliance];
    delete assocStateClone[`droppable-${index}`];
    const applianceToDelete = assocStateClone.appliances[index];
    assocStateClone.appliances.splice(index, 1);
    const allCircuitsOnAppliances = [];

    try {
      // Get all circuits left on appliances into an array and delete existing droppable props
      assocStateClone.appliances.forEach((appliance, i) => {
        if (`droppable-${i}` in assocStateClone) {
          const circuitsOnAppliance = assocStateClone[`droppable-${i}`];
          allCircuitsOnAppliances.push(...circuitsOnAppliance);

          delete assocStateClone[`droppable-${i}`];
        } else if (`droppable-${i + 1}` in assocStateClone) {
          const circuitsOnAppliance = assocStateClone[`droppable-${i + 1}`];
          allCircuitsOnAppliances.push(...circuitsOnAppliance);

          delete assocStateClone[`droppable-${i + 1}`];
        }
      });

      // Re-populate at new droppable indices
      assocStateClone.appliances.forEach((appliance, i) => {
        assocStateClone[`droppable-${i}`] = allCircuitsOnAppliances.filter((circuit) =>
          appliance.circuits.includes(circuit.id)
        );
      });

      this.props.setAssociationState(assocStateClone);

      if (applianceToDelete.appliance_id) {
        const session = await Auth.currentSession();
        await API.del('appliances', `/site/appliances/${applianceToDelete.appliance_id}`, {
          headers: { 'Content-Type': 'application/json', Authorization: session.getIdToken().getJwtToken() },
          body: applianceToDelete,
        });
      }

      toast.success('Successfully deleted appliance.');
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  handleSaveApplianceName = async (currentAppliance, index) => {
    const newAppliance = { ...currentAppliance };
    newAppliance.appliance_name = this.props.associationState.currentlyEditingApplianceName;

    try {
      // Otherwise, we'll create an appliance with this name and associate the circuits.
      const session = await Auth.currentSession();
      const circuitsForAppliance = this.props.associationState[`droppable-${index}`];

      newAppliance.circuits = circuitsForAppliance.map((c) => c.id);

      // Patch if it's an old appliance, post if it's a new one.
      if (newAppliance.appliance_id) {
        await API.patch('appliances', `/site/appliances/${newAppliance.appliance_id}`, {
          headers: { 'Content-Type': 'application/json', Authorization: session.getIdToken().getJwtToken() },
          body: newAppliance,
        });
      } else {
        await API.post('appliances', `/site/sites/${this.props.siteId}/appliances`, {
          headers: { 'Content-Type': 'application/json', Authorization: session.getIdToken().getJwtToken() },
          body: newAppliance,
        });
      }

      const appliances = [
        ...this.props.associationState.appliances.map((a, applianceIndex) => {
          if (index === applianceIndex) {
            return newAppliance;
          }
          return a;
        }),
      ];

      toast.success('Successfully saved appliance.');

      this.props.setAssociationState({
        ...this.props.associationState,
        appliances,
        editingIndex: -1,
        currentlyEditingApplianceName: null,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const stateClone = { ...this.props.associationState };
      const indexMovedFrom = source.droppableId !== 'circuits' ? source.droppableId.split('-')[1] : null;
      const indexMovedTo = destination.droppableId !== 'circuits' ? destination.droppableId.split('-')[1] : null;
      const movedCircuit = stateClone[source.droppableId][source.index];
      const applianceMovedTo = stateClone.appliances[indexMovedTo];
      const applianceMovedFrom = indexMovedFrom ? stateClone.appliances[indexMovedFrom] : null;
      let applianceToSave = null;

      if (destination.droppableId === 'circuits') {
        // Remove circuit id from appliance it was moved from
        const applianceMovedFrom = stateClone.appliances[indexMovedFrom];
        applianceMovedFrom.circuits = applianceMovedFrom.circuits.filter((c) => c !== movedCircuit.id);
        applianceToSave = applianceMovedFrom;
        movedCircuit.appliance_id = null;
      } else {
        applianceToSave = applianceMovedTo;

        // Ensure that this circuit's type matches the others in the list
        const applianceBucketMovedTo = stateClone[destination.droppableId];
        for (const circuit of applianceBucketMovedTo) {
          if (circuit.clipsal_monitors !== movedCircuit.clipsal_monitors) {
            toast.error(
              'All circuits on an appliance must have the same monitor type. Check the monitors table to confirm.'
            );
            return;
          }
        }
        // Add the circuits to the appliance model
        applianceMovedTo.circuits.push(movedCircuit.id);

        if (applianceMovedFrom) {
          applianceMovedFrom.circuits = applianceMovedFrom.circuits.filter((c) => c !== movedCircuit.id);
        }
      }

      const result = move(this.getList(source.droppableId), this.getList(destination.droppableId), source, destination);

      const newState = {
        ...stateClone,
        ...result,
      };

      this.props.setAssociationState(newState);

      try {
        const session = await Auth.currentSession();

        // Only save an appliance which was moved to - the moved from is modified automatically.
        if (indexMovedTo !== null) {
          const session = await Auth.currentSession();
          const circuits = newState[`droppable-${indexMovedTo}`]?.map((c) => c.id) ?? [];
          const body = {
            appliance_name: applianceToSave.appliance_name,
            circuits,
          };

          await API.post('appliances', '/site/sites/' + this.props.siteId + '/appliances', {
            headers: { 'Content-Type': 'application/json', Authorization: session.getIdToken().getJwtToken() },
            body,
          });
        }

        // If the circuit was moved to the circuit bucket, disassociate the appliance
        if (!indexMovedTo) {
          await API.post('circuits', '/site/sites/' + this.props.siteId + '/circuits', {
            headers: { 'Content-Type': 'application/json', Authorization: session.getIdToken().getJwtToken() },
            body: [movedCircuit],
          });
        }

        toast.success('Successfully saved appliance.');
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }
  };

  render() {
    return (
      <div>
        <hr />

        <h2>Circuit Association</h2>

        <p>
          Use the below drag and drop UI to associate specific circuits with specific appliances, then click the "Save"
          button.
        </p>

        <h3>Circuits</h3>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="circuits" direction={'horizontal'}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getCircuitListStyle(snapshot.isDraggingOver, this.props.associationState.circuits.length)}
              >
                {this.props.associationState.circuits.map((item, index) => (
                  <Draggable key={`circuit-${item.oem_circuit_id}`} draggableId={item.oem_circuit_id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={'badge badge-pill badge-primary'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        {item.circuit_name}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <h3>Appliances</h3>
          <div style={getContainerStyle()}>
            {this.props.associationState.appliances.map((a, i) => (
              <div key={`appliance-${i}`}>
                <div style={{ display: 'flex' }}>
                  {this.props.associationState.editingIndex === i ? (
                    <>
                      <input
                        ref={(input) => input && input.focus()}
                        style={{
                          margin: '0 3px',
                        }}
                        onChange={(e) => {
                          this.props.setAssociationState({
                            ...this.props.associationState,
                            currentlyEditingApplianceName: e.currentTarget.value,
                          });
                        }}
                        placeholder={'Appliance name'}
                        value={this.props.associationState.currentlyEditingApplianceName}
                      />
                      <button
                        className={'btn btn-success btn-sm'}
                        style={{
                          margin: '0 3px',
                        }}
                        onClick={() => this.handleSaveApplianceName(a, i)}
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <>
                      <h5
                        style={{
                          margin: '0 5px',
                        }}
                      >
                        {a.appliance_name}
                      </h5>
                      <button
                        style={{
                          margin: '0 3px',
                        }}
                        className={'btn btn-success btn-sm'}
                        disabled={!a.circuits.length}
                        onClick={() => {
                          this.props.setAssociationState({
                            ...this.props.associationState,
                            editingIndex: i,
                            currentlyEditingApplianceName: a.appliance_name,
                          });
                        }}
                      >
                        Edit
                      </button>
                    </>
                  )}
                  <button
                    style={{
                      margin: '0 3px',
                    }}
                    className={'btn btn-success btn-sm'}
                    onClick={() => this.handleDeleteAppliance(i)}
                  >
                    Delete
                  </button>
                </div>

                <Droppable droppableId={`droppable-${i}`} direction={'horizontal'}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(
                        snapshot.isDraggingOver,
                        !!this.props.associationState[`droppable-${i}`].length
                      )}
                    >
                      {this.props.associationState[`droppable-${i}`].length ? (
                        this.props.associationState[`droppable-${i}`]?.map((item, index) => (
                          <Draggable
                            key={`${a.appliance_name}-${item.oem_circuit_id}`}
                            draggableId={item.oem_circuit_id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={'badge badge-pill badge-primary'}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                {item.circuit_name}
                              </div>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <p>Drag items here. Note that an appliance must have associated circuits to rename it.</p>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}

            <div>
              <br />
              <Button
                onClick={this.handleAddAppliance}
                w="100%"
                fontSize="16px"
                py={10}
                mb={5}
                mt={-1}
                bg="gray.300"
                minHeight="60px"
              >
                + Add appliance
              </Button>
            </div>
          </div>
        </DragDropContext>

        <hr />
      </div>
    );
  }
}
