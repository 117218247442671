import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import { MS_PER_DAY } from './constants';

const secret = 'Efwer#97FhgtsklhjhgfD';

type obj = {
  [key: string]: unknown;
};

export const dateTimeFormat = (): string => {
  const today = new Date();
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  return date + ' ' + time;
};

export const isEmpty = (obj: obj): boolean => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

/**
 * Top-level validation mechanism for any form.
 *
 * @param formValues
 * @param fieldValidationConfigArray
 * @returns {string}
 */
export const formValidation = (
  formValues: Record<string, any>,
  fieldValidationConfigArray: Record<string, any>[]
): string => {
  let message = '';

  const findFirstInvalidField = (item) => {
    if (item.required) {
      const value = formValues[item.value];
      const isFieldEmpty = !value || Number(value) === -1;

      if (item.type === 'email') {
        return isFieldEmpty || !checkIfEmailValid(String(value));
      } else if (item.type === 'phone') {
        return isFieldEmpty || !checkIfPhoneNumberValid(value.toString());
      }

      return isFieldEmpty;
    }

    return false;
  };

  // Find the first invalid field
  const firstInvalidField = fieldValidationConfigArray.find(findFirstInvalidField);

  // Display a message for the invalid field
  if (firstInvalidField) {
    if (!formValues[firstInvalidField.value]) {
      message = firstInvalidField.label + ' field cannot be empty';
    } else {
      message = 'Please provide a valid ' + firstInvalidField.label;
    }
  }

  return message;
};

export const domain_validation = (email: string): boolean => {
  const notAllowedDomains = ['clipsalsolar.com'];
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isAllowedDomain = true;
  if (re.test(email)) {
    const emailarr = email.split('@');
    for (let i = 0; i < notAllowedDomains.length; i++) {
      if (notAllowedDomains[i] === emailarr[1]) {
        isAllowedDomain = false;
        break;
      }
    }
    return isAllowedDomain;
  } else {
    return false;
  }
};

/**
 * Applies a rudimentary regex test against the supplied email string.
 *
 * @param {string} email The email string to validate
 * @returns {boolean}
 */
export const checkIfEmailValid = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Applies a rudimentary regex test against the supplied phone number string.
 *
 * @param {string} phoneNumber The
 * @returns {boolean}
 */
export const checkIfPhoneNumberValid = (phoneNumber: string): boolean => {
  // Ensure the string length is 12 (11 numbers prefixed by one '+')
  return phoneNumber.length === 12 && /\+[0-9]{11}/.test(phoneNumber);
};

export const camelize = (str: string): string => {
  return str.toLowerCase().replace(/(?:(^.)|(\s+.))/g, function (match) {
    return match.charAt(match.length - 1).toUpperCase();
  });
};

export const encryptCodes = (passcode: string): string => {
  return CryptoJS.AES.encrypt(passcode, secret).toString();
};
export const decryptCodes = (encryptedCode: string): string => {
  return CryptoJS.AES.decrypt(encryptedCode, secret).toString(CryptoJS.enc.Utf8);
};

export const timestamp = (date: string): number => {
  const datestring = new Date(date).getTime();
  return datestring;
};

export const isObject = (obj: obj): boolean => {
  return obj !== undefined && obj !== null && obj.constructor == Object;
};

export const displayAPIErrorMessage = (error): void => {
  console.error(error);
  const hasResponseProperty = error?.response;

  const defaultErrMsg = (error) => {
    const errorMessage = error?.message;
    toast.error(errorMessage ? errorMessage : 'Internal server error', { autoClose: 5000 });
  };

  const responseErrMsg = (error) => {
    const errorMessage = error.response.data?.message;
    toast.error(errorMessage ? errorMessage : 'Internal server error', { autoClose: 5000 });
  };

  if (hasResponseProperty) return responseErrMsg(error);
  return defaultErrMsg(error);
};

type columnObj = {
  map_col: string;
};

export const lower_dict_keys = (dict_map: obj, columns: columnObj[]): obj => {
  const result: obj = {};
  for (const key in dict_map) {
    let mapObj;
    if (columns && columns.length > 0) {
      mapObj = columns.find((item) => item.map_col === key);
    }
    let map_key = key;
    if (mapObj) {
      map_key = mapObj.dataField;
    }
    let value = dict_map[key];

    if (key === 'time') {
      value = moment.utc(dict_map[key], 'X').format('YYYY-MM-DD HH:mm:ss');
    }
    try {
      result[map_key.toLowerCase()] = value;
    } catch (e) {
      result[map_key] = value;
    }
  }
  return result;
};
export const generate_payload = (list_data, defaults, columns, extracols) => {
  const payload: obj[] = [];

  for (let i = 0; i < list_data.length; i++) {
    let rcd = {};
    if (list_data[i].constructor === Object) {
      rcd = mapToObj(defaults, list_data[i]);

      if (extracols) {
        for (const key in extracols) {
          const val = extracols[key];
          rcd[key] = val;
        }
      }
    }
    payload.push(lower_dict_keys(rcd, columns));
  }
  return payload;
};

export const flatten_complex_element = (map, defaults, columns, extracols) => {
  const flattened = {};
  let list_element: obj[] | null = null;
  for (const key in map) {
    if (map[key].constructor === Object) {
      map = flatten_dict(key, map[key], flattened);
    } else if (Array.isArray(map[key])) {
      list_element = map[key];
    } else {
      flattened[key] = map[key];
    }
  }
  if (list_element === null) {
    list_element = [];
    list_element.push(flattened);
  } else {
    defaults = mapToObj(flattened, defaults);
  }
  return generate_payload(list_element, defaults, columns, extracols);
};
export const flatten_dict = (name, data, map) => {
  for (const key in data) {
    if (data.constructor === Object) {
      map = flatten_dict(key, data[key], map);
    } else {
      let key_to_use = key;
      if (key in map) {
        key_to_use = name + '_' + key;
      }
      map[key_to_use] = data[key];
    }
  }
  return map;
};
export const mapToObj = (map, existingmap): obj[] => {
  for (const key in map) existingmap[key] = map[key];
  return existingmap;
};

export const flatten = (data, defaults, columns, extracols) => {
  if (defaults === undefined) {
    defaults = {};
  }
  let data_stream: obj[] = [];
  let local_defaults = {};
  if (Object.keys(defaults).length > 0 && defaults.constructor === Object) {
    local_defaults = mapToObj(defaults, local_defaults);
  }
  let list_element: obj[] = [];

  if (Array.isArray(data)) {
    list_element = data;
  } else if (data.constructor === Object) {
    const keys = Object.keys(data);
    for (const key in keys) {
      if (Array.isArray(data[key])) {
        list_element = data[key];
      } else if (data.constructor === Object) {
        flatten_dict(key, data[key], local_defaults);
      } else {
        local_defaults[key] = data[key];
      }
    }
  }
  if (list_element.length > 0) {
    for (let i = 0; i < list_element.length; i++) {
      const flatten_list = flatten_complex_element(list_element[i], local_defaults, columns, extracols);
      data_stream = data_stream.concat(flatten_list);
    }
  } else {
    const flatten_list = flatten_complex_element(local_defaults, {}, columns, extracols);
    data_stream = data_stream.concat(flatten_list);
  }
  return data_stream;
};

/**
 * Formats a `Date` object, according to the string structure provided from the API.
 *
 * @param date - The date object to format.
 */
export function formatDate(date: Date): string {
  return `${date.getFullYear()}-${new Intl.DateTimeFormat('en', {
    month: '2-digit',
  }).format(date)}-${new Intl.DateTimeFormat('en', {
    day: '2-digit',
  }).format(date)}`;
}

/**
 * Formats a `Date` object to a date time string, according to the string structure provided from the API.
 *
 * @param date - The date object to format.
 */
export function formatDateTime(date: Date): string {
  const formattedDate = formatDate(date);
  const time = formatTime(date);

  return `${formattedDate} ${time}`;
}

/**
 * Formats a `Date` object to a time string as `HH:MM:SS`.
 *
 * @param date
 * @returns {string}
 */
export function formatTime(date: Date): string {
  return `${date.getHours()}:${date.getMinutes().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${date.getSeconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`;
}

/**
 * Calculates the time difference between two specified dates.
 *
 * @param start - The starting date, to be calculated from
 * @param end - The ending date, to be calculated to
 * @returns The number of days between the two specified dates.
 */
export function calculateDifferenceBetweenDates(start: Date, end: Date): number {
  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

/**
 * Used to debounce a timeout on an `onChange` or similar event emitter.
 *
 * @param fn - The callable which is pseudo-invoked following the delay.
 * @param delay - Time, in ms, to wait until the function is invoked.
 */
export function debounceEvent<T, R>(fn: (...fnArgs: T[]) => R, delay = 300) {
  let timeoutId: NodeJS.Timeout;

  return function (this: any, ...args: T[]) {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
}

/**
 * Capitalizes the first character of a given string
 *
 * @param str - The string to capitalize the first letter of
 * @returns A new string with the first letter captialized
 */
export const capitalizeFirstChar = (str: string): string => str.charAt(0).toUpperCase() + str.substring(1);
